<template>
  <div class="add_company">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>

    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      label-position="left"
    >
      <div class="auth" v-if="info.status == '审核失败' && note">
        <i class="iconfont iconE-gantanhao"></i>
        <p>审核失败：{{ note }}</p>
      </div>

      <div class="title d-flex">
        <div>企业管理/{{ status === "edit" ? "编辑" : "完善" }}企业信息</div>

        <div class="tips_icon">
          <i class="iconfont iconweirenzheng"></i>申请须知

          <div class="join_msg">
            <div class="txt">1.为了便于审核通过，请上传本人纸质名片图片；</div>
            <div class="txt">2.企业联系人姓名需与纸质版名片姓名保持一致；</div>
            <div class="txt">
              3.企业联系人号码、名片号码、小程序授权手机号需保持一致，如需修改，可在提交审核通过后修改；
            </div>
            <div class="txt">
              4.提交申请后，我们将有专员负责审核，若上述信息不属实将会导致审核失败。
            </div>
          </div>
        </div>
      </div>

      <div class="company bgc">
        <!-- 上传名片 -->
        <el-form-item label="名片图片" required>
          <div class="card-upload-box d-flex">
            <div class="card_upload">
              <el-upload
                action=""
                :file-list="fileList"
                :show-file-list="false"
                :before-upload="beforeCardFront"
                :http-request="upload_card_front"
                list-type="picture"
                accept=".jpg,.jpeg,.png"
              >
                <div class="btn_icon">
                  <i class="iconfont iconE-camera"></i>
                </div>
                <img :src="card_front ? card_front : front_img" alt="" />
              </el-upload>

              <p>上传名片正面</p>
            </div>

            <div class="card_upload">
              <el-upload
                action=""
                :file-list="fileList"
                :show-file-list="false"
                :before-upload="beforeCardBack"
                :http-request="upload_card_back"
                list-type="picture"
                accept=".jpg,.jpeg,.png"
              >
                <div class="btn_icon">
                  <i class="iconfont iconE-camera"></i>
                </div>
                <img :src="card_back ? card_back : back_img" alt="" />
              </el-upload>

              <p>上传名片反面</p>
            </div>
          </div>

          <el-dialog
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            class="user-dialog"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </div>

      <div class="title-link">企业信息</div>
      <div class="company bgc">
        <!-- 企业logo -->
        <el-form-item label="企业logo" prop="thumb">
          <!-- 上传图片 -->
          <div class="thumb_main">
            <el-upload
              class="updata_logo"
              action=""
              :file-list="fileList"
              :show-file-list="false"
              :before-upload="logoBeforeUpload"
              :http-request="logo_upload"
              list-type="picture"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-circle-plus-outline"></i>
              <div class="el-upload__text">上传图片</div>
            </el-upload>

            <div
              class="view_img"
              v-show="form.thumb"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
              @click="upload"
            >
              <img :src="form.thumb" alt="" />
              <div class="again_upload" v-show="again_box">重新上传</div>
            </div>
          </div>
        </el-form-item>

        <!-- 企业名称 -->
        <el-form-item label="企业名称" prop="company_name">
          <el-input
            v-model="form.company_name"
            placeholder="请输入"
            maxlength="16"
          ></el-input>
        </el-form-item>

        <!-- 企业简称 -->
        <el-form-item label="企业简称" prop="tag_name">
          <el-input
            class="impose"
            v-model="form.tag_name"
            placeholder="请输入"
            maxlength="8"
          ></el-input>
        </el-form-item>

        <!-- 主营方向 多选-->
        <el-form-item
          label="主营方向"
          prop="main_project_ids"
          class="main_projects"
        >
          <el-cascader
            v-model="form.main_project_ids"
            :options="options"
            :props="defaultParams"
            :show-all-levels="false"
            @change="handleChange"
            clearable
          ></el-cascader>
        </el-form-item>

        <!-- 企业介绍 -->
        <el-form-item label="企业简介" prop="tag_description">
          <textarea
            placeholder="请输入"
            v-model="form.tag_description"
          ></textarea>
        </el-form-item>

        <!-- 企业地址 -->
        <el-form-item label="企业地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入"
            maxlength="30"
          ></el-input>
        </el-form-item>

        <!-- 企业网址 -->
        <el-form-item label="企业网址" prop="company_link">
          <el-input v-model="form.company_link" placeholder="请输入"></el-input>
        </el-form-item>
      </div>

      <div class="title-link">联系人信息</div>
      <div class="link bgc">
        <!-- 联系人 -->
        <el-form-item label="联系人" prop="name">
          <el-input
            class="impose"
            v-model="form.name"
            placeholder="请输入"
            maxlength="6"
          ></el-input>
        </el-form-item>

        <!-- 联系方式 -->
        <el-form-item label="联系方式" prop="tel">
          <el-input
            class="impose"
            type="text"
            v-model="form.tel"
            placeholder="请输入"
            hide-required-asterisk
            status-icon
            maxlength="11"
            @input="ipt_user_tel"
          >
          </el-input>
        </el-form-item>

        <!-- 验证码 -->
        <el-form-item label="验证码" prop="code">
          <el-input
            class="codeipt"
            type="text"
            v-model="form.code"
            placeholder="请输入"
            maxlength="6"
          >
          </el-input>
          <el-button
            class="codebtn"
            :class="code_dis ? '' : 'can_btn'"
            @click="getCode"
            :disabled="code_dis"
            >{{ code_txt }}</el-button
          >
        </el-form-item>

        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
          <el-input
            class="impose"
            v-model="form.email"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-button
          class="create_btn"
          @click="submitForm"
          :disabled="disable_flag"
          >提交</el-button
        >
        <el-button class="back_btn" @click="back">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import store from "@/store";
// import { oss } from "@/api/oss_upload";
import { getData, postData } from "@/api/user";

export default {
  props: ["status"],
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!this.isCellPhone(value)) {
        //引入methods中封装的检查手机格式的方法
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      info: {},
      active: "",
      userid: store.state.app.userInfo.id,
      edit_company_info: {}, //  编辑企业信息
      form: {
        thumb: "", // logo
        company_name: "", // company_name
        tag_name: "", // tag_name
        main_project_ids: [], // 主营方向
        tag_description: "", // tag_description
        address: "", // 企业地址
        company_link: "", // 企业网址
        name: "", // 联系人
        tel: "", // 联系电话
        code: "", //验证码
        register_key: "",
        email: "", // 邮箱
      },
      rules: {
        card: [
          {
            required: true,
          },
        ],
        thumb: [
          {
            required: true,
            message: "请上传企业logo",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        tag_name: [
          {
            required: true,
            message: "请输入企业简称",
            trigger: "blur",
          },
        ],
        main_project_ids: [
          {
            type: "array",
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入联系人",
          },
        ],
        tel: [
          {
            required: true,
            validator: checkphone,
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      // 多选
      defaultParams: {
        expandTrigger: "hover",
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      options: [], //  主营方向
      main_projects_list: [], // 主营方向 id集
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [], // 上传图片

      editor: null,
      defaultMsg: "", // 富文本默认提示信息
      /*富文本配置项*/
      config: {
        initialFrameWidth: 800, // 宽度
        initialFrameHeight: 350, // 高度
      },
      again_box: false, // 重新上传样式
      disable_flag: false,
      // 验证码
      code_dis: true,
      code_txt: "获取验证码",
      num: 60,
      clock: null,
      // 名片
      ali_oss: store.state.app.ali_oss,
      oss_url: store.state.app.uploadpath,
      uploadHost: "https://ebaina.oss-cn-hangzhou.aliyuncs.com",
      uploadData: {}, // 附件上传携带参数
      // 审核失败提示
      note: "",

      card_front: "", // 正面
      card_back: "", // 反面
      front_img:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/2021329-172545.png",
      back_img:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/2021329-172551.png",
    };
  },
  created() {
    let class_url = `api/b_admin/get_tag_linkage`;
    getData({
      url: class_url,
      data: {},
    }).then((res) => {
      let data = res.data;
      if (data.code === 200) {
        this.options = data.data;
        this.dg(data.data);
      }
    });

    // 编辑
    if (this.status === "edit") {
      // let info = localStorage.getItem("User_edit");

      this.edit_init(store.state.app.supplier_id);
    } else {
      // 小程序 公司名称地址 回填
      let userid = this.userid;
      getData({
        url: `api/b_admin/check_company/${userid}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.form.company_name = data.data.info.supplier_name
            ? data.data.info.supplier_name
            : "";
          this.form.address = data.data.info.supplier_address
            ? data.data.info.supplier_address
            : "";
        }
      });
    }
  },
  methods: {
    edit_init(supplier_id) {
      // console.log(info.main_projects_info);
      getData({
        url: `api/b_admin/supplier/edit/${supplier_id}`,
        data: {},
      }).then((res) => {
        console.log("企业编辑》》》》", res.data);
        let data = res.data;
        if (data.code === 200) {
          let info = data.data.info;
          this.info = info;
          this.note = info.note;
          this.card_front = info.card_front;
          this.card_back = info.card_back;
          this.code_dis = false;

          // 图片
          this.fileList = [{ url: info.tags.thumb }];
          this.form.thumb = info.tags.thumb;

          // 分类
          let pro_ids = [];
          info.main_projects_info.forEach((v, i) => {
            let id = v.id;
            let child_id = v.children.id;
            let v_arr = [];
            v_arr[0] = id;
            v_arr[1] = child_id;
            pro_ids[i] = v_arr;
          });
          this.form.main_project_ids = pro_ids;

          this.form.company_name = info.company_name;
          this.form.tag_name = info.tags.name;
          this.form.tag_description = info.tags.description;
          this.form.address = info.address;
          this.form.company_link = info.company_link;
          this.form.name = info.name;
          this.form.tel = info.tel;
          this.form.email = info.email;
          this.edit_company_info = info;
        }
      });
    },
    mouseOver() {
      if (this.form.thumb) {
        this.again_box = true;
      }
    },
    mouseLeave() {
      this.again_box = false;
    },
    // 名片正面
    upload_card_front(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.card_front = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },
    beforeCardFront(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
    },
    // 名片反面
    upload_card_back(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.card_back = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },
    beforeCardBack(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
    },

    // 个人头像
    upload() {
      document.querySelector(".updata_logo .el-upload__input").click();
    },
    // 分类
    handleChange(arr) {
      // console.log("选择分类----", arr);
      if (arr.length >= 6) {
        this.$warning("最多选择5个主营方向，只保留前5个主营方向");
        this.form.main_project_ids = arr.splice(0, 5);
      } else {
        this.form.main_project_ids = arr;
      }
    },
    // before
    logoBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传企业logo大小不能超过 1MB!");
        return false;
      }
    },
    logo_upload(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.form.thumb = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },

    //检查手机号
    isCellPhone(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },

    // 获取验证码
    getCode() {
      this.$refs.form.validateField("tel", (errMsg) => {
        if (!errMsg) {
          this.code_dis = true;

          let params;
          if (this.status === "edit") {
            params = {
              phone: this.form.tel,
              supplier_id: this.info.id,
            };
          } else {
            params = { phone: this.form.tel };
          }
          postData({
            url: `api/b_admin/${this.userid}/get_supplier_phone_qrcode`,
            data: params,
          })
            .then((res) => {
              let data = res.data;
              if (data.code === 200) {
                this.form.register_key = data.data.pin_code;
                this.$success("短信验证码已发送");
                this.code_txt = this.num + "s";
                this.clock = setInterval(() => {
                  this.num--;
                  if (this.num > 0) {
                    // this.code_txt = this.num + "s";
                    this.code_txt = `${this.num}s`;
                  } else {
                    clearInterval(this.clock);
                    this.code_dis = false;
                    this.code_txt = "获取验证码";
                    this.num = 60;
                  }
                }, 1000);
              } else if (data.code == 422) {
                if (data.data.phone) {
                  this.$err(data.data.phone[0]);
                }
                this.code_dis = false;
              }
            })
            .catch(() => {
              this.code_dis = false;
            });
        }
      });
    },

    ipt_user_tel(e) {
      if (e) {
        // this.code_dis = true;

        if (!this.clock) {
          this.code_dis = false;
        }
      }
    },
    // 提交
    submitForm() {
      if (this.card_front === "") {
        return this.$err("请上传名片正面");
      }

      if (this.card_back === "") {
        return this.$err("请上传名片反面");
      }

      if (this.form.register_key === "") {
        return this.$err("请点击获取验证码");
      }

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.disable_flag = true;

          let params = {};
          let arr = [];
          let list = this.form.main_project_ids.slice(0, 5);
          list.forEach((item, i) => {
            arr[i] = item[1];
          });
          let min_pros = arr;

          if (this.status === "edit") {
            params = {
              thumb: this.form.thumb,
              company_name: this.form.company_name,
              tag_name: this.form.tag_name,
              main_project_ids: min_pros,
              tag_description: this.form.tag_description,
              address: this.form.address,
              company_link: this.form.company_link,
              name: this.form.name,
              tel: this.form.tel,
              code: this.form.code,
              register_key: this.form.register_key,
              email: this.form.email,
              id: this.edit_company_info.id,
              card_front: this.card_front,
              card_back: this.card_back,
            };
          } else {
            params = {
              thumb: this.form.thumb,
              company_name: this.form.company_name,
              tag_name: this.form.tag_name,
              main_project_ids: min_pros,
              tag_description: this.form.tag_description,
              address: this.form.address,
              company_link: this.form.company_link,
              name: this.form.name,
              tel: this.form.tel,
              code: this.form.code,
              register_key: this.form.register_key,
              email: this.form.email,
              card_front: this.card_front,
              card_back: this.card_back,
            };
          }

          postData({
            url: `api/b_admin/${this.userid}/project`,
            data: params,
          })
            .then((res) => {
              //console.log(res);
              let data = res.data;
              if (data.code === 200) {
                this.$success(data.data.msg, false, () => {
                  this.$emit("changeTemp", {
                    template: "UserIndex",
                    item: "1",
                    id: "1-1",
                  });
                });
              } else if (data.code === 421) {
                this.$err(data.data.msg);
                this.disable_flag = false;
              } else if (data.code === 422) {
                if (data.data.message) {
                  this.$err(data.data.message);
                } else {
                  this.$err(data.data.msg);
                }
                this.disable_flag = false;
              }
            })
            .catch(() => {
              this.$err("请求失败，请检查网络");
              this.disable_flag = false;
            });
        } else {
          //("error submit!!");
          return false;
        }
      });
    },
    dg(list) {
      list.forEach((v) => {
        v.value = v.id;
        v.label = v.name;
        if (v.children) {
          this.dg(v.children);
        }
      });

      return list;
    },
    back() {
      this.$emit("changeTemp", { template: "UserIndex", item: "1", id: "1-1" });
    },
  },
  components: {
    topnav,
  },
};
</script>

<style lang="less" scoped>
.add_company {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;

    .tips_icon {
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      flex-shrink: 0;
      cursor: pointer;

      .iconfont {
        margin-right: 8px;
      }

      .join_msg {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 20px;
        width: 320px;
        height: 216px;
        background: #ffffff;
        border: 1px solid #f5f5f5;
        border-radius: 4px;

        .txt {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }

    .tips_icon:hover {
      .join_msg {
        display: block;
      }
    }
  }

  .title-link {
    padding: 30px 50px 0;
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
    background: white;
  }

  .el-form {
    label {
      margin-right: 16px !important;
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a !important;
    }

    .main_projects {
      width: 100%;
    }
  }
  .company {
    padding: 25px 134px 3px 50px;
  }
  .link {
    padding: 30px 134px 40px 50px;
  }

  textarea {
    padding: 16px 20px;
    width: 100%;
    height: 124px;
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: "iconfont";
    resize: none;
  }

  .create_btn {
    display: inline-block;
    padding: 0 60px;
    margin: 0 0 0 100px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background: #1b4fa8;
    cursor: pointer;
    border: none;
  }

  .create_btn:hover {
    color: #ffffff;
    background: #1b4fa8;
  }

  .back_btn {
    display: inline-block;
    padding: 0 60px;
    margin: 0 0 0 50px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #dcdfe6;
    color: #606266;
    background: #fff;
    cursor: pointer;
  }

  .back_btn:hover {
    color: #606266;
    background: #fff;
    border: 1px solid #dcdfe6;
  }
}
</style>

<style lang="less">
.add_company {
  .el-form {
    .el-input {
      width: 100%;
      height: 40px;
      border: none;

      input {
        height: 100%;
        border: 1px solid #ccc;
      }
    }
  }

  .impose {
    width: 300px !important;
  }
  .impose.el-input.is-disabled .el-input__inner {
    background-color: #fff;
    color: #606266;
  }

  .codeipt {
    width: 170px !important;
  }

  .codebtn,
  .codebtn:hover,
  .codebtn:focus,
  .codebtn:active,
  .codebtn.is-disabled,
  .codebtn.is-disabled:hover,
  .codebtn.is-disabled:focus,
  .codebtn.is-disabled:active {
    width: 120px;
    margin-left: 10px;
    border-color: none;
    color: #666666;
    background: #e5e5e5;
    border-radius: 4px;
  }

  .can_btn {
    color: #fff !important;
    background-color: #1b4fa8 !important;
  }

  .main_projects {
    .el-cascader {
      width: 100%;

      .el-input {
        width: 100%;
        height: 100%;

        input {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .thumb_main {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
    color: #2657ac;
    border: 1px solid #e5e5e5;

    i {
      margin-top: 15px;
      font-size: 26px;
    }

    .el-upload__text {
      line-height: 1;
    }

    .el-upload-dragger {
      width: 80px !important;
      height: 80px !important;
    }

    .view_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      background: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .again_upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 80px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  .hide .el-upload--picture-card {
    display: none;
  }

  .user-dialog .el-dialog {
    height: auto !important;
    width: 30% !important;
  }

  .card-upload-box {
    .card_upload {
      margin-right: 25px;

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 1;
      }

      .el-upload {
        position: relative;
        width: 140px;
        height: 81px;
        background: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #e5e5e5;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .btn_icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 33px;
          height: 33px;
          background: #1b4fa8;
          border-radius: 50%;

          .iconfont {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }
      }
    }
  }

  .auth {
    display: flex;
    align-items: center;
    height: 46px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #fe0303;
    background: #ffe4e4;
    border: 1px solid #ffefe0;
    margin-bottom: 10px;
    .iconfont {
      font-size: 14px;
      color: #fe0303;
      margin: 0 10px 0 20px;
    }
  }
}
</style>
