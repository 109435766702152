<template>
  <div class="login">
    <div class="erweima">
      <!-- 左侧common -->
      <div class="left_img">
        <div class="title">推广好方案 就用云方案</div>
        <div class="img_erweima">
          <img src="@/assets/images/wx_program.png" alt="" />
        </div>
        <img
          class="right_bottom_img"
          src="@/assets/images/login_img.png"
          alt=""
        />
      </div>

      <!-- 二维码 -->
      <div class="right_erweima" v-show="erweima_show">
        <div class="title">微信快速登录</div>
        <img :src="erweimaUrl" alt="" />
      </div>

      <!-- 手机号注册 -->
      <div class="phone_login" v-show="phone_show">
        <div class="title">手机号注册</div>

        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- 昵称 -->
          <el-form-item prop="nickname">
            <el-input
              type="text"
              v-model="form.nickname"
              autocomplete="off"
              hide-required-asterisk
              placeholder="请输入昵称"
              prefix-icon="el-icon-user"
            >
            </el-input>
          </el-form-item>

          <!-- 手机号 -->
          <el-form-item prop="phone">
            <el-input
              type="text"
              v-model="form.phone"
              autocomplete="off"
              hide-required-asterisk
              placeholder="请输入手机号"
              maxlength="11"
              prefix-icon="el-icon-phone"
            >
            </el-input>
          </el-form-item>

          <!-- 验证码 -->
          <el-form-item prop="q_code">
            <el-input
              type="text"
              v-model="form.q_code"
              placeholder="请输入验证码"
              autocomplete="off"
              hide-required-asterisk
              maxlength="6"
              prefix-icon="el-icon-message"
            >
            </el-input>

            <el-button
              type="text"
              class="get_q_code"
              :disabled="timeout"
              @click="get_code"
            >
              {{ q_code_msg }}
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" class="submit_btn" @click="submitForm"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
import { mapActions } from "vuex";
import Cookies from "js-cookie";
// import store from "@/store";

export default {
  name: "login",
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!this.isCellPhone(value)) {
        //引入methods中封装的检查手机格式的方法
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var q_code = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不能为空"));
      } else if (value.length != 6) {
        //引入methods中封装的检查手机格式的方法
        callback(new Error("验证码为6位数字"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      erweimaUrl: "",
      erweima_show: true,
      phone_show: false,
      form: {
        nickname: "",
        phone: "",
        q_code: "",
        openid: "",
        provider: "",
        key: "",
        register_key: "",
        origin_user_info: "",
      },
      rules: {
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        phone: [{ required: true, validator: checkphone, trigger: "blur" }],
        q_code: [{ required: true, validator: q_code, trigger: "change" }],
      },
      q_code_msg: "发送验证码",
      timeout: false,
      time_Interval: "",
      inter_login: "",
      login:'user', // 登录跳转的路径
    };
  },
  created() {
    getData({
      url: "api/b_admin/qrcode",
      data: {},
    }).then((res) => {
      let data = res.data;
      if (data.code === 200) {
        this.erweimaUrl = data.data.qrcode_url;
        this.get_inter_login(data.data.channel_id);
      }
    });
    this.login = localStorage.getItem("login")
  },
  methods: {
    ...mapActions(["handleLogin"]),
    // 获取验证码
    get_code() {
      if (this.form.phone.trim() === "") return;

      let count = Cookies.get("phoneCountLimit");
      if (Number(count) > 3) {
        this.$warning("每天获取验证码次数不能超过三次");
        return false;
      }

      let that = this;
      that.timeout = true;

      let nickname = this.form.nickname.trim();
      let phone = this.form.phone;
      let openid = this.form.openid;
      let provider = this.form.provider;
      let url = "api/b_admin/sendsmspin";
      postData({
        url: url,
        data: { nickname, phone, openid, provider },
      }).then((res) => {
        let data = res.data;
        // 倒计时
        let num = 60;
        clearInterval(that.time_Interval);
        that.time_Interval = setInterval(() => {
          that.q_code_msg = `${num}s`;

          if (num <= 0) {
            that.q_code_msg = "重新获取验证码";
            that.timeout = false;
            clearInterval(that.time_Interval);
          }
          num--;
        }, 1000);

        if (data.code === 200) {
          this.form.key = data.data.key;
          this.form.register_key = data.data.pin_code;
          this.form.origin_user_info = data.data.origin_user_info;
          this.count_limit();
        } else if (data.code === 422) {
          this.$warning(data.data.phone[0]);
        }
      });
    },
    // 提交 手机号注册
    submitForm() {
      let that = this;
      that.$refs["form"].validate((valid) => {
        if (valid) {
          let id = "";
          if (that.form.origin_user_info) {
            id = that.form.origin_user_info.id;
          }
          let params = {
            nickname: that.form.nickname.trim(),
            phone: that.form.phone,
            code: that.form.q_code,
            key: that.form.key,
            register_key: that.form.register_key,
            origin_user_id: id,
          };

          let url = "api/b_admin/register";
          postData({
            url: url,
            data: params,
          }).then((res) => {
            let data = res.data;
            if (data.code === 200) {
              that.$success(data.data.message, false, () => {
                that.handleLogin(data.data);
                that.$router.push({
                  name: that.login,
                });
              });
            } else if (data.code === 422) {
              that.$err(data.data.message);
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取扫码状态
    get_inter_login(channel_id) {
      let that = this;
      that.inter_login = setInterval(() => {
        let url = `check_for_wait_login/${channel_id}`;
        getData({
          url: url,
          data: {},
        }).then((res) => {
          let data = res.data;
          // console.log("data---", data.data);
          if (data.data.result) {
            clearInterval(that.inter_login);
            let info = data.data.result;
            that.form.openid = info.token;
            that.form.provider = info.provider;
            that.form.origin_user_info = info.origin_user_info;
            that.form.nickname = info.nickname;

            if (info.verification === false) {
              that.get_phone();
              clearInterval(that.inter_login);
            } else {
              //console.log("data---", data.data);
              clearInterval(that.inter_login);
              that.handleLogin(info);
              that.$refs["form"].resetFields();
              that.$router.push({
                name: that.login,
              });
            }
          }
        });
      }, 3000);
    },
    //检查手机号
    isCellPhone(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    // 手机注册页面
    get_phone() {
      this.erweima_show = false;
      this.phone_show = true;
    },
    // 获取验证码 一天3次
    count_limit() {
      let count = Cookies.get("phoneCountLimit");

      if (count !== null && count !== undefined && count !== "") {
        count = Number(count) + 1;
      } else {
        count = 1;
      }

      Cookies.set("phoneCountLimit", count, { expires: 1 });
    },
  },
};
</script>



<style lang="less" scoped>
.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 500px;
}

.erweima {
  display: flex;
  widows: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;

  .left_img {
    position: relative;

    width: 350px;
    height: 500px;
    text-align: center;
    background: linear-gradient(183deg, #40599b 0%, #4e71c1 100%);
    flex-shrink: 0;

    .title {
      margin: 84px 0 38px;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }

    .img_erweima {
      img {
        width: 144px;
        height: 146px;
      }
    }

    .right_bottom_img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 226px;
      height: 191px;
    }
  }

  .right_erweima {
    width: 100%;
    height: 100%;
    text-align: center;

    .title {
      margin: 103px 0 39px;
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }

    img {
      width: 200px;
      height: 200px;
    }
  }
}

.phone_login {
  .title {
    margin: 84px 0 34px 100px;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }

  .get_q_code {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 16px;
    color: #1b4fa8;
  }

  .submit_btn {
    width: 300px;
    height: 46px;
    color: #fff;
    background: #1b4fa8;
  }

  .el-form-item {
    .el-input {
      width: 300px;
      height: 46px;
      input {
        padding-left: 50px;
        height: 100% !important;
      }
    }
  }
}
</style>
