<template>
  <div class="add_peoject">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <div class="title">
      方案中心/{{ status === "edit" ? "编辑" : "添加" }}方案
    </div>
    <div class="project-wrap bgc">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <!-- 应用场景 -->
        <el-form-item label="应用场景" prop="classify_id">
          <el-cascader
            v-model="form.classify_id"
            :options="options"
            :props="defaultParams"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>

        <!-- 方案标题 -->
        <el-form-item label="方案标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>

        <!-- 方案图片 -->
        <el-form-item label="方案图片" prop="thumbs" class="thumbs d-flex">
          <!-- 上传图片 -->
          <div class="left thumbs_box">
            <el-upload
              ref="lup"
              action=""
              list-type="picture"
              :file-list="l_fileList"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :http-request="l_img_upload"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-circle-plus-outline"></i>
              <div class="el-upload__text">上传图片</div>
            </el-upload>

            <div
              class="view_img"
              v-show="form.l_thumb"
              @mouseover="l_mouseOver"
              @mouseleave="l_mouseLeave"
              @click="l_upload"
            >
              <img :src="form.l_thumb" alt="" />
              <div class="again_upload" v-show="l_again_box">重新上传</div>
            </div>
          </div>

          <!-- 右侧图片 -->
          <div class="right thumbs_box">
            <el-upload
              ref="rup"
              action=""
              list-type="picture"
              :file-list="r_fileList"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :http-request="r_img_upload"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-circle-plus-outline"></i>
              <div class="el-upload__text">上传图片</div>
            </el-upload>

            <div
              class="view_img"
              v-show="form.r_thumb"
              @mouseover="r_mouseOver"
              @mouseleave="r_mouseLeave"
              @click="r_upload"
            >
              <img :src="form.r_thumb" alt="" />
              <div class="again_upload" v-show="r_again_box">重新上传</div>
            </div>
          </div>
        </el-form-item>

        <!-- 标签-->
        <el-form-item label="标签" prop="tags">
          <div class="tags_box">
            <div class="chick_tag_box d-flex">
              <div
                class="badge"
                v-for="(item, index) in check_tags"
                :key="index"
                @click.stop="remove_tags(item.id)"
              >
                <span>{{ item.name }}</span>
                <i class="iconfont iconE-guanbi"></i>
              </div>

              <div class="add_click" @click.stop="add_tags_input">
                <i class="iconfont iconE-shangchuanziliao"></i>
                最多可添加3个标签
              </div>
            </div>

            <div class="info_tags_box" v-show="serach_tags">
              <div class="ser_input">
                <el-autocomplete
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="搜索关键词/标签"
                  @select="handleSelect"
                ></el-autocomplete>
              </div>

              <div class="tag-title">热门标签</div>
              <div class="hot-tags d-flex">
                <span
                  class="badge"
                  @click.stop="choose_tags(item.id, item.name)"
                  v-for="item in hotTags"
                  :key="item.id"
                >
                  {{ item.name }}
                </span>
              </div>

              <div class="query_btns d-flex">
                <!-- <div class="btns" @click="add_tags_input">确定</div> -->
                <div class="btns cancel" @click.stop="add_tags_input">关闭</div>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 方案品牌 -->
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="form.brand" placeholder="请输入"></el-input>
        </el-form-item>

        <!-- 购买链接 -->
        <el-form-item label="购买链接" prop="buy_link">
          <el-input v-model="form.buy_link" placeholder="请输入"></el-input>
        </el-form-item>

        <!-- 方案介绍 -->
        <el-form-item label="方案介绍" prop="introduction" class="summary_box">
          <vue-ueditor-wrap
            v-model="form.introduction"
            :config="ueConfig"
            class="editor"
          ></vue-ueditor-wrap>

          <!-- 文档转换 -->
          <div class="about_document d-flex">
            <div
              class="left_doucment_ipt"
              :class="form.file_name !== pdf_placeholder ? 'color333' : ''"
            >
              {{ form.file_name }}

              <i
                class="iconfont iconshibai"
                @click="pdf_remove"
                v-if="form.file_name !== pdf_placeholder"
              ></i>
            </div>

            <el-upload
              class="upload_btn"
              :action="uploadHost"
              :data="pdf_uploadData"
              :file-list="pdf_fileList"
              :before-upload="pdf_BeforeUpload"
              :on-success="pdf_success"
              :show-file-list="false"
              accept=".pdf,.ppt,.pptx"
              :disabled="pdf_flag"
            >
              <el-button :disabled="pdf_flag">
                {{ pdf_updataBtnTxt }}
              </el-button>
            </el-upload>
          </div>

          <div class="icon_tip_box d-flex">
            <div class="icon_tip d-flex">
              <i class="iconfont iconE-xiaoxi"></i>
              <div class="tip_txt">文档要求</div>

              <div class="view_tip d-none">
                <div class="txt">1.支持上传的文档格式：.pdf,.ppt,.pptx</div>
                <div class="txt">2.每个文档大小不超过50M</div>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 相关资料 -->
        <el-form-item label="相关资料" prop="resource">
          <!-- 上传资料 -->
          <div class="about_resource d-flex">
            <el-upload
              class="upload-demo"
              :action="uploadHost"
              :data="resource_uploadData"
              :file-list="resource_fileList"
              :before-upload="resource_BeforeUpload"
              :on-success="resource_success"
              :on-remove="resource_remove"
              :accept="resource_accept"
              :show-file-list="true"
              :multiple="true"
            >
              <el-button size="small" class="recourse_btn">
                <i class="iconfont iconupdata"></i>
                点击上传
              </el-button>
            </el-upload>

            <div class="icon_tip_box d-flex">
              <div class="icon_tip d-flex">
                <i class="iconfont iconE-xiaoxi"></i>
                <div class="tip_txt">资料要求</div>

                <div class="view_tip d-none">
                  <div class="txt">
                    1.支持上传的文档格式：doc,docx,xls,xlsx,pdf,ppt,pptx,txt,zip,rar,tar,7z,gz,jpg,jpeg,png,gz
                  </div>
                  <div class="txt">2.每个文档大小不超过50M</div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <el-button class="create_btn" @click="submitForm" :disabled="disable_flag"
        >提交</el-button
      >
      <el-button class="back_btn" @click="back">返回</el-button>
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import { oss } from "@/api/oss_upload";
import store from "@/store";
import { getData, postData } from "@/api/user";
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  props: ["status"],
  data() {
    var checkthumbs = (rule, value, callback) => {
      if (!this.form.l_thumb && !this.form.r_thumb) {
        callback(new Error("请最少上传一张图片"));
      } else {
        callback();
      }
    };
    return {
      active: "",
      ali_oss: store.state.app.ali_oss,
      oss_url: store.state.app.uploadpath,
      submit_url: "api/b_admin/projects",
      form: {
        classify_id: [],
        title: "",
        introduction: "",
        resource: [],
        l_thumb: "",
        r_thumb: "",
        brand: "",
        buy_link: "",
        tags: [], // 标签
        task_id: "",
        dir: "",
        file_name: "请上传产品文档，该文档将被直接解析展示在方案介绍内",
      },
      rules: {
        classify_id: [
          {
            required: true,
            message: "请选择应用场景",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入方案标题",
            trigger: "blur",
          },
        ],
        thumbs: [
          {
            type: "array",
            required: true,
            validator: checkthumbs,
            trigger: "change",
          },
        ],
        introduction: [
          {
            required: true,
            message: "请输入方案介绍",
            trigger: "change",
          },
        ],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      options: [], //  分类
      defaultParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },

      restaurants: [], // 搜索标签
      state: "", // 标签
      timeout: null, // 标签

      serach_tags: false,
      hotTags: [], // 热门标签
      ser_tags: [],
      check_tags: [],
      check_tags_id: [],
      check_tags_name: [],

      uploadHost: "", // 附件上传路径
      l_fileList: [],
      r_fileList: [],
      l_again_box: false,
      r_again_box: false,

      resource_uploadData: {}, // 资料
      resource_fileList: [], // 资料
      resource_accept:
        ".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.txt,.zip,.rar,.tar,.7z,.png,.jpg,.jpeg,.gz",

      pdf_uploadData: {}, // 资料 pdf
      pdf_fileList: [], // 资料 pdf
      pdf_placeholder: "请上传产品文档，该文档将被直接解析展示在方案介绍内",
      pdf_flag: false,
      pdf_updataBtnTxt: "上传",

      defaultMsg: "", // 富文本默认提示信息
      /*富文本配置项*/
      ueConfig: {
        toolbars: [
          [
            "bold",
            "|",
            "insertorderedlist",
            "insertunorderedlist",
            "|",
            "paragraph",
            "|",
            "insertimage",
            "|",
            "undo",
            "redo",
          ],
        ],
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        elementPathEnable: false,
        wordCount: true,
        UEDITOR_HOME_URL: "/ueditor/",
      },
      disable_flag: false,
      userCompany: {}, // 用户企业信息
    };
  },
  created() {
    let userInfo = store.state.app.userInfo;
    if (
      !userInfo.supplier_id ||
      userInfo.supplier_id === 0 ||
      userInfo.supplier_id === null ||
      userInfo.supplier_id === undefined
    ) {
      setTimeout(() => {
        this.$alert("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            this.$emit("changeTemp", { template: "UserCreate" });
          },
        });
      }, 50);
    } else {
      getData({
        url: `api/b_admin/check_status/${userInfo.supplier_id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          if (this.status === "edit") {
            this.edit_init(store.state.app.project_id);
          } else {
            this.init();
          }
          this.get_tag_linkage();
          this.hot_tags();

          let that = this;
          document.addEventListener("click", function (e) {
            let class_name = e.target.className;
            if (
              class_name !== "info_tags_box" &&
              class_name !== "el-input__inner" &&
              class_name !== "tag-title" &&
              class_name !== "hot-tags d-flex" &&
              class_name !== "edui-toolbar   edui-default" &&
              class_name !== "ser_input" &&
              class_name !== "query_btns d-flex"
            ) {
              that.serach_tags = false;
            }
          });
        } else {
          setTimeout(() => {
            this.$alert("请等待企业审核通过", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.$emit("changeTemp", {
                  template: "UserIndex",
                  item: "1",
                  id: "1-1",
                });
              },
            });
          }, 50);
        }
      });
    }
  },
  methods: {
    init() {
      let userid = store.state.app.userInfo.id;
      let url = `api/b_admin/${userid}/project`;
      getData({
        url: url,
        data: {},
      }).then((res) => {
        let data = res.data;
        // console.log("企业信息---", data);
        if (data.code === 200) {
          if (data.data !== null) {
            let info = data.data;
            let tag = info.tag;
            this.userCompany = info;
            if (tag) {
              tag.value = tag.name;
              this.check_tags.push(tag);
              this.check_tags_id.push(tag.id);
              this.check_tags_name.push(tag.name);
              this.restaurants = [tag];
            }
          }
        }
      });
    },
    edit_init(id) {
      this.submit_url = `api/b_admin/projects/${id}`;

      getData({
        url: `api/b_admin/projects/${id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          let info = data.data.info;
          // console.log("bianji>>>", info);

          // 分类
          let options = info.classify;
          let new_classify = {};
          let child = { name: options.name, id: options.id };
          new_classify = {
            name: options.top_classify.name,
            id: options.top_classify.id,
            children: child,
          };
          this.form.classify_id = [new_classify.id, child.id];
          this.form.l_thumb = info.thumbs[0];
          if (info.thumbs[1]) {
            this.form.r_thumb = info.thumbs[1];
          }
          this.form.title = info.title;
          this.form.introduction = info.introduction;
          this.form.brand = info.brand;
          this.form.buy_link = info.buy_link;
          this.form.file_name = info.file_name || this.pdf_placeholder;
          this.pdf_updataBtnTxt = info.file_name ? "重新上传" : "上传";

          // 图片
          let new_filelist = [];
          let list = JSON.parse(JSON.stringify(info.thumbs));
          list.forEach((v) => {
            let obj = { url: v };
            new_filelist.push(obj);
          });
          this.l_fileList = [new_filelist[0]];
          if (new_filelist.length > 1) {
            this.r_fileList = [new_filelist[1]];
          }

          // 资料
          let new_fileArr = [];
          let r_list = JSON.parse(JSON.stringify(info.resource));
          if (r_list) {
            r_list.forEach((v) => {
              let obj = {
                url: v.path,
                name: v.name,
                id: v.id,
              };
              new_fileArr.push(obj);
            });
            this.resource_fileList = new_fileArr;
            this.form.resource = new_fileArr;
          }

          let tags = info.content_tags;
          if (tags) {
            tags.forEach((v) => {
              v.value = v.name;

              this.check_tags.push(v);
              this.check_tags_id.push(v.id);
              this.check_tags_name.push(v.name);
            });
            this.restaurants = tags;
          }
        }
      });
  
  },
    get_tag_linkage() {
      let class_url = `api/b_admin/get_tag_linkage`;
      getData({
        url: class_url,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.options = data.data;
          this.dg(data.data);
        }
      });
    },
    // 分类
    handleChange(value) {
      this.form.classify_id = value;
    },

    // ----------left--图片---------------------
    // before
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }
    },
    l_img_upload(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.form.l_thumb = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },
    l_mouseOver() {
      if (this.form.l_thumb) {
        this.l_again_box = true;
      }
    },
    l_mouseLeave() {
      this.l_again_box = false;
    },
    l_upload() {
      this.$refs.lup.clearFiles();
      this.$refs.lup.$children[0].$refs.input.click();
    },
    // ----------left--图片----end-----------------

    // ----------right--图片---------------------
    r_img_upload(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.form.r_thumb = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },
    r_mouseOver() {
      if (this.form.r_thumb) {
        this.r_again_box = true;
      }
    },
    r_mouseLeave() {
      this.r_again_box = false;
    },
    r_upload() {
      this.$refs.rup.clearFiles();
      this.$refs.rup.$children[0].$refs.input.click();
    },
    // ----------right--图片-----end----------------

    // ------------pdf 上传 文档转换---------------------
    pdf_BeforeUpload(file) {
      this.pdf_flag = true;
      // console.log("file_type>>>>>>", file);
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 50MB!");
        this.pdf_flag = false;
        return false;
      }

      const url = "oss_direct/sign_key?dir=project";
      return oss(file.name, url).then((res) => {
        this.uploadHost = res.host;
        this.pdf_uploadData = res;

        this.$notify.info({
          title: "消息",
          message: "文件上传中",
        });
      });
    },
    pdf_success(res, file, fileList) {
      this.pdf_fileList = fileList;
      // console.log("上传成功", res);
      if (res.code === 200) {
        this.form.file_name = file.name;
        this.pdf_updata_change(res.filename);
        this.pdf_updataBtnTxt = "重新上传";

        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      } else {
        this.$warning({
          title: "上传失败",
          desc: "请重新上传",
        });
      }
    },
    pdf_remove() {
      this.pdf_fileList = [];
      this.form.file_name = this.pdf_placeholder;
    },
    pdf_updata_change(file_name) {
      let url = `/api/b_admin/document_type_convert`;
      getData({
        url: url,
        data: {
          oss_path: file_name,
        },
      })
        .then((res) => {
          let data = res.data;
          // console.log("上传pdf >>>>>>>>", res.data);
          if (data.code === 200) {
            if (
              data.data.info.Status == "Running" ||
              data.data.info.Status == "Finished"
            ) {
              this.form.task_id = data.data.info.TaskId;
              this.form.dir = data.data.info.dir;
            } else if (data.data.info.Status == "Failed") {
              this.$err({
                title: "转换失败",
                desc: "请重新上传",
              });
              this.form.file_name = this.pdf_placeholder;
            }
            this.pdf_flag = false;
          } else {
            this.pdf_flag = false;
          }
        })
        .catch(() => {
          this.pdf_flag = false;
        });
    },

    // ------------pdf 上传 文档转换---------------------

    // ------------相关资料---------------------
    resource_BeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 50MB!");
        return false;
      }

      const url = "oss_direct/sign_key?dir=project";
      return oss(file.name, url).then((res) => {
        this.uploadHost = res.host;
        this.resource_uploadData = res;
      });
    },
    resource_success(res, file, fileList) {
      this.resource_fileList = fileList;
      if (res.code === 200) {
        // let name = this.ali_oss + res.filename;
        let name = res.filename;
        let obj = {
          name: file.name,
          url: name.trim(),
          id: "",
        };
        this.form.resource.push(obj);
      } else {
        this.$warning({
          title: "上传失败",
          desc: "请重新上传",
        });
      }
    },
    resource_remove(file, fileList) {
      this.resource_fileList = fileList;
      this.form.resource = fileList;
    },
    // ------------相关资料-----end----------------

    // 标签
    hot_tags() {
      let url = `/api/b_admin/get_hot_taglist`;
      getData({
        url: url,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.hotTags = data.data;
        }
      });
    },

    add_tags_input() {
      this.serach_tags = !this.serach_tags;
      this.state = "";
    },

    // 搜索标签
    querySearchAsync(queryString, cb) {
      let url = `/api/b_admin/tags/matching?name=${
        queryString ? queryString : " "
      }`;
      getData({
        url: url,
        data: {},
      }).then((res) => {
        let data = res.data;
        // console.log(data);
        if (data.code === 200) {
          let list = data.data.list;
          if (list) {
            list.forEach((v) => {
              v.value = v.name;
            });
            this.restaurants = list;

            cb(list);
          }
        }
      });
    },
    handleSelect(item) {
      // console.log("item--", item);
      this.choose_tags(item.id, item.name);
    },

    // 添加标签
    choose_tags(id, name) {
      // console.log(id, name);
      let obj = { id, name };

      if (this.check_tags_id.length > 2) {
        return this.$warning("最多选择3个标签");
      }

      let idx = this.check_tags_id.indexOf(id);
      if (idx === -1) {
        this.check_tags.push(obj);
        this.check_tags_id.push(id);
        this.check_tags_name.push(name);
      } else {
        return this.$warning("标签已存在");
      }
    },
    remove_tags(id) {
      let idx = this.check_tags_id.indexOf(id);

      if (idx !== -1) {
        this.check_tags.splice(idx, 1);
        this.check_tags_id.splice(idx, 1);
        this.check_tags_name.splice(idx, 1);
      }
    },
    // 提交   获取ueditor内容方法 this.editor.getContent()
    submitForm() {
      let that = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.disable_flag = true;

          let list = [that.form.l_thumb, that.form.r_thumb];
          let url = this.submit_url;
          let params = {
            classify_id: that.form.classify_id[1],
            title: that.form.title,
            thumbs: list.filter((v) => v !== ""),
            introduction: that.form.introduction,
            resource: that.form.resource,
            brand: that.form.brand,
            buy_link: that.form.buy_link,
            tags: that.check_tags_id,
            task_id: that.form.task_id,
            dir: that.form.dir,
            file_name:
              that.form.file_name === that.pdf_placeholder
                ? ""
                : that.form.file_name,
          };
          postData({
            url: url,
            data: params,
          })
            .then((res) => {
              let data = res.data;
              if (data.code === 200) {
                this.$success(data.data.msg, false, () => {
                  // this.$router.push({ path: "/project_index" });
                  this.$emit("changeTemp", {
                    template: "ProjectIndex",
                    item: "2",
                    id: "2-1",
                  });
                });
              } else if (data.code === 422) {
                this.$err(data.data.msg);
                this.disable_flag = false;
              } else if (data.code === 421) {
                this.$err(data.data.msg);
                this.disable_flag = false;
              } else {
                this.$err(data.data.msg);
                this.disable_flag = false;
              }
            })
            .catch(() => {
              this.$err("请求失败，请检查网络");
              this.disable_flag = false;
            });
        } else {
          return false;
        }
      });
    },
    dg(list) {
      list.forEach((v) => {
        v.value = v.id;
        v.label = v.name;
        if (v.children) {
          this.dg(v.children);
        }
      });

      return list;
    },
    back() {
      this.$emit("changeTemp", {
        template: "ProjectIndex",
        item: "2",
        id: "2-1",
      });
    },
  },
  components: {
    topnav,
    VueUeditorWrap,
  },
};
</script>

<style lang="less" scoped>
.add_peoject {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;
  }

  .project-wrap {
    padding: 25px 50px;
    max-width: 990px;
  }

  .header-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }

  .el-form {
    label {
      margin-right: 16px !important;
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a !important;
    }

    .el-cascader,
    .el-input {
      width: 300px !important;
    }
  }

  .create_btn {
    display: inline-block;
    padding: 0 60px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background: #1b4fa8;
    cursor: pointer;
    border: none;
    margin-left: 80px;
  }

  .create_btn:hover {
    color: #ffffff;
    background: #1b4fa8;
  }

  .back_btn {
    display: inline-block;
    padding: 0 60px;
    margin: 0 0 0 50px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #dcdfe6;
    color: #606266;
    background: #fff;
    cursor: pointer;
  }

  .back_btn:hover {
    color: #606266;
    background: #fff;
    border: 1px solid #dcdfe6;
  }

  .editor {
    line-height: 1;
  }

  .summary_box {
    margin-bottom: 15px !important;
  }
}
</style>

<style lang="less">
.add_peoject {
  .el-form {
    .el-input {
      width: 300px !important;
      // height: 46px;
      height: 40px !important;

      border: none;

      input {
        height: 100%;
        border: 1px solid #ccc;
      }
    }

    .el-form-item {
      margin-bottom: 20px;

      .tags_box {
        position: relative;
        height: 46px;

        .badge {
          align-items: center;
          margin-right: 10px;
          padding: 0 6px;
          height: 22px !important;
          line-height: 22px !important;
          font-size: 12px;
          color: #418de3;
          background: #dcecff;
          cursor: pointer;

          .iconfont {
            margin-left: 5px;
            text-decoration: none;
            font-size: 12px;
            color: #666666;
          }
        }

        .chick_tag_box {
          align-items: center;

          .add_click {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            cursor: pointer;

            .iconfont {
              color: #418de3;
            }
          }
        }

        .info_tags_box {
          position: absolute;
          z-index: 1000;
          padding: 20px;
          width: 400px;
          // height: 260px;
          min-height: 200px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(153, 153, 153, 1);
          border-radius: 2px;
          opacity: 1;

          .ser_input {
            .el-autocomplete {
              margin-bottom: 13px;
              width: 100%;
              // height: 34px !important;
              line-height: 1;
              background: rgba(255, 255, 255, 1);
              border: 1px solid rgba(165, 165, 165, 1);
              border-radius: 2px;

              .el-input {
                width: 100%;
                height: 100%;
                border: none;

                input {
                  width: 100%;
                  height: 100%;
                  border: none;
                }

                input:focus {
                  border: none !important;
                }
              }
            }
          }

          .hot-tags {
            flex-wrap: wrap;
            line-height: 1;

            .badge {
              margin-bottom: 6px;
            }
          }

          .query_btns {
            width: 100%;
            justify-content: flex-end;

            .btns {
              width: 54px;
              height: 30px;
              line-height: 30px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              background: #418de3;
              border-radius: 2px;
              cursor: pointer;
            }

            .cancel {
              margin-left: 20px;
              color: #418de3;
              border: 1px solid #418de3;
              background: #fff;
            }
          }
        }
      }
    }
  }

  .main_projects {
    .el-cascader {
      width: 300px;

      .el-input {
        width: 100%;
        height: 100%;

        input {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .thumbs {
    .el-form-item__content {
      display: flex;
      margin-left: 0 !important;
    }

    .thumbs_box {
      margin-right: 20px;
      position: relative;
      width: 80px;
      height: 80px;
      text-align: center;
      color: #2657ac;
      border: 1px solid #e5e5e5;

      i {
        margin-top: 15px;
        font-size: 26px;
      }

      .el-upload__text {
        line-height: 1;
      }

      .el-upload-dragger {
        width: 80px !important;
        height: 80px !important;
      }

      .view_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: 4px;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .again_upload {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          line-height: 80px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          z-index: 2;
        }
      }
    }
  }

  /* 文档转换 */
  .about_document {
    margin-top: 10px;
    border: 1px solid #e5e5e5;

    .left_doucment_ipt {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;

      position: relative;
      padding: 0 40px 0 20px;
      width: 100%;
      height: 40px;
      line-height: 40px;

      font-size: 14px;
      font-weight: 400;
      color: #999999;

      .iconfont {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    .color333 {
      color: #333;
    }

    .upload_btn {
      height: 40px;
    }

    .el-button,
    .is-disabled:hover {
      padding: 0;
      width: 80px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      background: #1b4fa8;
      border: none;
      border-radius: 0;
      flex-shrink: 0;
    }
  }

  .icon_tip_box {
    justify-content: flex-end;

    .icon_tip {
      position: relative;
      align-items: center;
      margin: 9px 0 0;
      height: 15px;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 1;
      justify-content: flex-end;
      white-space: nowrap;
      cursor: pointer;

      .iconfont {
        margin-right: 8px;
      }

      .tip_txt {
        line-height: 1.1;
      }

      .view_tip {
        position: absolute;
        top: 33px;
        right: 0;
        padding: 16px 20px 9px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        z-index: 999;

        .txt {
          margin-bottom: 7px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 1;
          white-space: nowrap;
        }
      }
    }

    .icon_tip:hover {
      .view_tip {
        display: block;
      }
    }
  }

  .about_resource {
    position: relative;
    // width: 600px;
    justify-content: space-between;

    .upload-demo {
      width: 100%;
      overflow: hidden;
    }

    .recourse_btn {
      padding: 0 11px;
      height: 34px;
      color: #1b4fa8;
      background-color: #fff;
      border: 1px solid #1b4fa8;
      border-radius: 6px;

      span {
        display: flex;
        align-items: center;
      }

      .iconfont {
        margin-right: 5px;
      }
    }

    .icon_tip {
      align-items: baseline;
    }
  }
}
</style>
