import { getHomeRoute } from '@/libs/util'
import { setTokens, getTokens } from '@/libs/util'
import config from '@/libs/config'
const { homeName } = config

export default {
  state: {
    homeRoute: {},
    ali_oss: 'https://ebaina.oss-cn-hangzhou.aliyuncs.com/',
    wr_base_url: 'http://view.officeapps.live.com/op/view.aspx?src=', // 微软文档预览 官方地址
    userInfo: {},
    userName: '',
    userId: '',
    avatarImgPath: '', // 头像
    token: getTokens(),
    project_id: '',
    article_id: '',
    supplier_id: '',
  },
  mutations: {
    setHomeRoute (state, routes) {
      state.homeRoute = getHomeRoute(routes, homeName)
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setAvatar (state, avatarPath) {
      state.avatarImgPath = avatarPath
    },
    setUserId (state, id) {
      state.userId = id
    },
    setUserName (state, name) {
      state.userName = name
    },
    setToken (state, token) {
      state.token = token
      setTokens(token)
    },
    setProjectId (state, project_id) {
      state.project_id = project_id
    },
    setArticleId (state, article_id) {
      state.article_id = article_id
    },
    setSupplierId (state, supplier_id) {
      state.supplier_id = supplier_id
    },
  },
  actions: {
    // 登录
    handleLogin ({ commit }, info) {
      //console.log('handleLogin------', info);
      commit('setToken', info.jwt_token)
      commit('setUserInfo', info.user_info)
      commit('setUserId', info.user_info.id)
      commit('setUserName', info.user_info.nickname)
      commit('setAvatar', info.user_info.avatar)

      let vuex_info = this.state.app
      localStorage.setItem('store_user', JSON.stringify(vuex_info))
    },
    // 退出登录
    handleLogOut ({ commit }) {
      commit('setToken', '')
      localStorage.removeItem('store_user', '')
      localStorage.setItem("login", 'user')
      window.location.href = '/'
    },
  }
}
