<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getTokens, setTokens } from "@/libs/util";

export default {
  name: "App",
  created() {
    let token = getTokens();
    if (!token) {
      localStorage.removeItem("store_user");
    }

    let user = JSON.parse(localStorage.getItem("store_user"));
    // console.log("缓存app--", user);

    if (user !== null && user !== undefined && user.userId !== "") {
      // console.log("user 应该有值--", user);
      this.$store.state.app = user;
    }

    // 刷新之前 保存vuex缓存
    window.addEventListener("beforeunload", () => {
      let app_user = this.$store.state.app;

      if (!app_user.userId) {
        localStorage.setItem("store_user", "");
        setTokens("");
      } else {
        localStorage.setItem("store_user", JSON.stringify(app_user));
      }
    });
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100vw;
}
</style>