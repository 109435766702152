<template>
  <!-- 方案管理 -->
  <div class="peoject_index">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>

    <div class="bgc" v-loading="loading">
      <div class="title">方案中心/方案管理</div>
      <div class="is_project" v-if="pro_data">
        <div class="top_ser">
          <div class="ipts d-flex">
            <span>方案分类:</span>
            <el-cascader
              v-model="classify"
              :options="options"
              :props="defaultParams"
              @change="handleChange"
              clearable
            ></el-cascader>

            <span>方案标题:</span>
            <input v-model="tiile" placeholder="请输入" />
            <span>上传用户:</span>
            <input v-model="nickname" placeholder="请输入" />
          </div>

          <div class="r_btnsr d-flex">
            <span @click="ser_ipt">查询</span>
            <span @click="add_pro">添加方案</span>
          </div>
        </div>

        <div class="table_box">
          <el-table
            class="table-project"
            :data="tableData"
            fit
            :row-style="tableRowStyle"
            :cell-style="tableCellStyle"
            :header-cell-style="tableHeaderColor"
          >
            <el-table-column label="方案标题" width="280">
              <template slot-scope="scope">
                <div class="pro_title d-flex">
                  <img class="form_img" :src="scope.row.thumbs[0]" alt="" />
                  <span>{{ scope.row.title }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="time" label="发布时间"></el-table-column>
            <el-table-column property="name" label="上传用户"></el-table-column>
            <el-table-column property="cate" label="方案分类"></el-table-column>
            <el-table-column property="updated_at" label="上架">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.checkBol"
                  active-color="#1B4FA8"
                  inactive-color="#999"
                  @change="changeStatus($event, scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column property="updated_at" width="150" label="操作">
              <template slot-scope="scope">
                <div class="actions d-flex">
                  <span @click="read(scope.row)">查看</span>
                  <span @click="edit(scope.row)">编辑</span>
                  <span @click="remove_project(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="page_size"
            :page-sizes="page_sizes"
            layout="prev, pager, next, jumper"
            :total="total"
            background
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
      <div class="no_project" v-else>
        <img class="no_data" src="@/assets/images/no_project_data.png" alt="" />
        <div>暂无方案</div>
        <div class="add_project" @click="add_pro">去添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import store from "@/store";
import { getData, postData } from "@/api/user";
import config from "@/libs/config";

export default {
  data() {
    return {
      loading: true,
      active: "",
      classify: ["", ""], // 分类
      tiile: "", // 标题
      nickname: "", // 上传用户
      ali_oss: store.state.app.ali_oss,
      wr_base_url: store.state.app.wr_base_url,
      show_view: false,
      pro_data: true,
      dialogVisible: false, //  提示框
      tableData: [],

      // 分页
      page: 1, // 分页
      page_size: 10, // 每页条数
      page_sizes: [10, 20, 30, 40], // 分页
      total: 0, // 分页

      options: [], //  分类
      defaultParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
    };
  },
  created() {
    let userInfo = store.state.app.userInfo;
    if (!userInfo.supplier_id) {
      this.loading = false;
      setTimeout(() => {
        this.$alert("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            this.$emit("changeTemp", { template: "UserCreate" });
          },
        });
      }, 50);
    } else {
      this.loading = false;
      getData({
        url: `api/b_admin/check_status/${userInfo.supplier_id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.loading = true;
          this.tabelist(1);
          this.get_tag_linkage();
        } else {
          setTimeout(() => {
            this.$alert("请等待企业审核通过", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.$emit("changeTemp", {
                  template: "UserIndex",
                  item: "1",
                  id: "1-1",
                });
              },
            });
          }, 50);
        }
      });
    }
  },
  methods: {
    tableRowStyle() {
      return {
        color: "#333",
        fontWeight: "400",
        fontSize: "14px",
      };
    },
    tableCellStyle({ columnIndex }) {
      if (columnIndex === 0) {
        return {
          padding: "20px 0  20px 20px",
        };
      } else {
        return {
          padding: "20px 0  20px 0",
        };
      }
    },
    tableHeaderColor({ rowIndex, columnIndex }) {
      if (rowIndex === 0 && columnIndex === 0) {
        return "color: #000;font-weight: 400;font-size: 16px; height: 58px;background: #f7f8fa;padding:20px 0  20px 20px";
      } else {
        return "color: #000;font-weight: 400;font-size: 16px; height: 58px;background: #f7f8fa;padding:20px 0  20px 0";
      }
    },
    tabelist(type) {
      let classify_id = [];
      if (this.classify[1]) {
        classify_id = this.classify[1];
      } else {
        classify_id = "";
      }
      let title = this.tiile;
      let nickname = this.nickname;
      let url = `api/b_admin/projects?page=${this.page}&pagesize=${this.page_size}&title=${title}&classify_id=${classify_id}&nickname=${nickname}`;
      getData({
        url: url,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.show_view = true;
          data.data.data.forEach((v) => {
            v.checkBol = v.online === "上架";
            v.time = v.created_at.substr(0, 10);
            v.name = v.user ? (v.user.nickname ? v.user.nickname : "") : "";
            v.cate = v.classify ? (v.classify.name ? v.classify.name : "") : "";
          });
          this.tableData = data.data.data;
          this.total = data.data.total;
          let userinfo = store.state.app.userInfo;
          if (type === 1) {
            if (
              data.data.data &&
              data.data.data.length > 0 &&
              userinfo.supplier_id !== 0
            ) {
              this.pro_data = true;
            } else {
              this.pro_data = false;
            }
          }

          this.loading = false;
        }
      });
    },
    get_tag_linkage() {
      let class_url = `api/b_admin/get_tag_linkage`;
      getData({
        url: class_url,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.options = data.data;
          this.dg(data.data);
        }
      });
    },
    // 方案分类 搜索
    handleChange(value) {
      this.classify = value;
    },
    // 编辑
    edit(row) {
      this.$emit("changeTemp", {
        template: "ProjectCreate",
        item: "2",
        id: "2-2",
        status: "edit",
      });
      this.$store.commit("setProjectId", row.id);
      // this.$router.push({ name: "project_edit" }); // 只能用 name
      // localStorage.setItem("pro_edit", JSON.stringify(row));
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.page = val;
      this.tabelist();
    },
    // 查看
    read(row) {
      window.open(config.baseUrl.pro + `projects/${row.id}`);
    },
    // 删除
    remove_project(row) {
      this.$confirm("确定删除此方案吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          //console.log(row);
          let url = `api/b_admin/del_projects/${row.id}`;
          getData({
            url: url,
            data: {},
          }).then((res) => {
            if (res.data.code === 200) {
              this.$success(res.data.data.msg, false, () => {
                this.tabelist();
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 搜索
    ser_ipt() {
      this.tabelist();
    },
    // 新增
    add_pro() {
      let userInfo = store.state.app.userInfo;

      if (
        !userInfo.supplier_id ||
        userInfo.supplier_id === 0 ||
        userInfo.supplier_id === null ||
        userInfo.supplier_id === undefined
      ) {
        this.$confirm("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$emit("changeTemp", { template: "UserCreate" });
            // this.$router.push({ path: "/User_create" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$emit("changeTemp", {
          template: "ProjectCreate",
          item: "2",
          id: "2-2",
          status: "add",
        });
        // this.$router.push({ path: "/project_create" });
      }
    },
    // 上下线
    changeStatus(val, row) {
      var str = val ? "上架" : "下架";
      let url = `api/b_admin/projects/down_projects/${row.id}`;
      postData({
        url: url,
        data: {
          online: str,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$success(str + "成功", false, () => {
            this.tabelist();
          });
        }
      });
    },
    dg(list) {
      list.forEach((v) => {
        v.value = v.id;
        v.label = v.name;
        if (v.children) {
          this.dg(v.children);
        }
      });

      return list;
    },
  },
  components: {
    topnav,
  },
};
</script>

<style lang="less" scoped>
.peoject_index {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;
  }
  .is_project {
    padding: 20px;
    .top_ser {
      margin: 0 0 20px;
      align-items: center;
      flex: flex-wrap;
      padding: 25px 30px;
      width: 100%;
      background: #f7f8fa;

      .ipts {
        align-items: center;
        margin-bottom: 20px;

        span {
          margin-right: 13px;
          font-size: 14px;
          font-weight: 400;
          color: #1a1a1a;
        }

        input {
          margin-right: 25px;
          padding: 0 15px;
          width: 180px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #f2f4f9;
        }
      }

      .r_btnsr {
        span {
          margin-right: 15px;
          padding: 0 19px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          background: #1b4fa8;
          cursor: pointer;
        }
      }
    }
    .pro_title {
      align-items: center;

      .form_img {
        margin-right: 8px;
        height: 60px;
        width: 60px;
        object-fit: contain;
      }
    }
    .actions {
      justify-content: space-between;
      width: 120px;
      text-align: center;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #1b4fa8;
        cursor: pointer;
      }
    }
  }

  .no_project {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 160px;

    .no_data {
      margin: 133px 0 0;
      width: 329px;
      height: 283px;
    }

    .add_project {
      margin: 20px auto 0;
      width: 101px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      color: #ffffff;
      background: #1b4fa8;
      cursor: pointer;
    }
  }
  // 分页
  .pagination {
    margin: 40px auto;
    text-align: center;
  }
}
</style>

<style lang="less">
.peoject_index {
  .ipts {
    .el-cascader {
      margin-right: 25px;

      .el-input {
        width: 180px;
        height: 32px !important;
        border: none;

        input {
          width: 100%;
          height: 100%;
          border: 1px solid #f2f4f9;
        }
      }
    }
  }
  .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
      .number {
        height: 30px;
        line-height: 30px;
        color: #1b4fa8;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
      .number:hover {
        color: #1b4fa8 !important;
      }

      .active:hover {
        color: #fff !important;
      }
    }

    .active {
      background-color: #1b4fa8 !important;
    }

    button {
      height: 30px;
      line-height: 30px;
      background-color: #fff !important;
      border: 1px solid #1b4fa8;

      span {
        padding: 0 12px;
        font-size: 16px;
        color: #1b4fa8;
      }
    }

    .el-pagination__jump {
      margin-left: 0;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #1b4fa8;

      .el-input__inner {
        color: #1b4fa8;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
    }
  }
}
</style>
