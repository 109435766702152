<template>
  <!-- 需求管理 -->
  <div class="need_index">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <div class="title">需求中心/需求管理</div>
    <div class="need-wrap bgc" v-loading="loading">
      <div class="is_project" v-if="pro_data">
        <div class="top_ser">
          <!-- 搜索条件 -->
          <div class="ipts d-flex">
            <span>状态:</span>
            <el-select v-model="status" placeholder="请选择">
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 按钮组 -->
          <div class="r_btnsr d-flex">
            <span @click="tabelist">查询</span>
            <span @click="add_pro">新增需求</span>
          </div>
        </div>
        <!-- 表格 -->
        <div class="table_box">
          <el-table
            class="table-project"
            :data="tableData"
            fit
            :row-style="tableRowStyle"
            :cell-style="tableCellStyle"
            :header-cell-style="tableHeaderColor"
          >
            <el-table-column property="title" label="需求标题" width="280">
            </el-table-column>
            <el-table-column
              property="created_at"
              label="创建时间"
            ></el-table-column>
            <el-table-column property="status" label="状态"></el-table-column>
            <el-table-column
              property="count"
              label="申请用户"
            ></el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <div class="actions d-flex">
                  <span class="code" @click="read(scope.row)">查看 </span>
                  <span
                    @click="edit(scope.row)"
                    v-show="scope.row.status === '进行中'"
                    >编辑</span
                  >
                  <span
                    @click="offline(scope.row)"
                    v-show="scope.row.status === '进行中'"
                    >下线</span
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="page_size"
            :page-sizes="page_sizes"
            layout="prev, pager, next, jumper"
            :total="total"
            background
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 无数据 -->
      <div class="no_project" v-else>
        <img class="no_data" src="@/assets/images/no_project_data.png" alt="" />
        <div>暂无需求信息</div>
        <div class="add_project" @click="add_pro">去添加</div>
      </div>
    </div>
    <!-- 查看弹窗 -->
    <el-dialog
      class="read-dialog"
      title="查看"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      show-close
      :append-to-body="true"
      :close="handleClose"
      width="50%"
    >
      <el-table :data="readData" fit v-loading="readLoad">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column property="nickname" label="昵称"></el-table-column>
        <el-table-column property="phone" label="手机号码"></el-table-column>
        <el-table-column
          property="supplier_name"
          label="所属公司"
          width="200"
        ></el-table-column>
        <el-table-column
          property="updated_at"
          label="沟通时间"
        ></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="readCurrentChange"
          :current-page.sync="page_read"
          :page-size="page_size_read"
          :page-sizes="page_sizes_read"
          layout="prev, pager, next, jumper"
          :total="total_read"
          background
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import { getData, postData } from "@/api/user";

export default {
  data() {
    return {
      // 布局
      active: "", // 顶部
      loading: true,
      pro_data: true, // 是否有数据
      userInfo: this.$store.state.app.userInfo,

      // 搜索条件
      status: "",
      statusList: ["进行中", "已下线"],

      // 表格
      tableData: [],
      code: "",
      codeShow: false,

      // 分页
      page: 1, // 分页
      page_size: 10, // 每页条数
      page_sizes: [10, 20, 30, 40], // 分页
      total: 0, // 分页

      // 查看
      dialogVisible: false,
      readData: [],
      row_id: 0,
      page_read: 1, // 分页
      page_size_read: 5, // 每页条数
      page_sizes_read: [5, 10, 20, 30], // 分页
      total_read: 0, // 分页
      readLoad: true,
    };
  },
  created() {
    this.tabelist(1);
  },
  mounted() {
    var that = this;
    document.addEventListener("click", function (e) {
      if (e.target.className !== "code") {
        that.tableData.forEach((v) => {
          v.show = false;
        });
      }
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    tabelist(type) {
      this.loading = true;
      let url = `api/b_admin/demands`;
      getData({
        url: url,
        data: {
          page: this.page,
          pageSize: this.page_size,
          status: this.status,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          data.data.data.forEach((v) => {
            v.show = false;
          });
          this.tableData = data.data.data;
          this.total = data.data.total;
          // 第一次进页面判断
          if (type === 1) {
            if (data.data.data.length > 0 && this.userInfo.supplier_id !== 0) {
              this.pro_data = true;
            } else {
              this.pro_data = false;
            }
          }

          this.loading = false;
        }
      });
    },
    // 查看
    read(row) {
      this.row_id = row.id;
      this.readLoad = true;
      this.dialogVisible = true;
      this.readList();
    },
    readList() {
      let that = this;
      let url = `api/b_admin/demands/show_apply/` + this.row_id;
      getData({
        url: url,
        data: {
          page: this.page_read,
          pagesize: this.page_size_read,
        },
      })
        .then((res) => {
          this.readLoad = false;
          if (res.data.code === 200) {
            this.readData = res.data.data.list.data;
            this.total_read = res.data.data.list.total;
            this.readData.forEach((v) => {
              v.nickname = v.user
                ? v.user.nickname
                  ? v.user.nickname
                  : ""
                : "";
              v.phone = v.user ? (v.user.phone ? v.user.phone : "") : "";
            });
          } else {
            this.readData = [];
            this.total_read = 0;
          }
        })
        .catch(() => {
          that.$err("请求失败，请检查网络");
          // that.disable_flag = false;
        });
    },
    // 编辑
    edit(row) {
      this.$router.push({
        name: "need_create",
        query: { id: row.id, status: "edit" },
      });
    },
    // 下线
    offline(row) {
      this.$confirm("确定下线此需求吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          //console.log(row);
          let url = `api/b_admin/demands/down_demands/${row.id}`;
          postData({
            url: url,
            data: {},
          }).then((res) => {
            if (res.data.code === 200) {
              this.$success(res.data.data.msg, false, () => {
                this.tabelist();
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 新增
    add_pro() {
      this.$router.push({ path: "/need_create" });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.tabelist();
    },
    readCurrentChange(val) {
      this.page_total = val;
      this.readList();
    },
    tableRowStyle() {
      return {
        color: "#333",
        fontWeight: "400",
        fontSize: "14px",
      };
    },
    tableCellStyle({ columnIndex }) {
      if (columnIndex === 0) {
        return {
          padding: "30px 0  30px 20px",
        };
      } else {
        return {
          padding: "30px 0  30px 0",
        };
      }
    },
    tableHeaderColor({ rowIndex, columnIndex }) {
      if (rowIndex === 0 && columnIndex === 0) {
        return "color: #000;font-weight: 400;font-size: 16px; height: 58px;background: #f7f8fa;padding:30px 0  30px 20px";
      } else {
        return "color: #000;font-weight: 400;font-size: 16px; height: 58px;background: #f7f8fa;padding:30px 0  30px 0";
      }
    },
  },
  components: {
    topnav,
  },
};
</script>

<style lang="less" scoped>
.need_index {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;
  }
  .is_project {
    padding: 20px;
    .top_ser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 20px;
      align-items: center;
      flex: flex-wrap;
      padding: 25px 30px;
      width: 100%;
      background: #f7f8fa;

      .ipts {
        align-items: center;

        span {
          margin-right: 13px;
          font-size: 14px;
          font-weight: 400;
          color: #1a1a1a;
        }

        input {
          margin-right: 25px;
          padding: 0 15px;
          width: 180px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #f2f4f9;
        }
      }

      .r_btnsr {
        span {
          margin-right: 15px;
          padding: 0 19px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          background: #1b4fa8;
          cursor: pointer;
        }
      }
    }

    .table_box {
      .member-li-header {
        justify-content: space-between;
        align-items: center;
        padding-left: 50px;
        padding-right: 15px;
        width: 100%;
        height: 58px;
        line-height: 58px;
        background: #f7f8fa;
      }

      .member-ul {
        li {
          justify-content: space-between;
          align-items: center;
          padding-left: 50px;
          padding-right: 15px;
          height: 89px;
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
          border-bottom: 1px solid #e5e5e5;
        }
      }

      .pro_title {
        width: 220px;

        .form_img {
          margin-right: 8px;
          width: 58px;
          height: 59px;
        }
      }

      .created_time {
        width: 85px;
      }

      .up_user {
        width: 130px;
      }

      .up_classify {
        width: 130px;
      }

      .actions {
        justify-content: space-between;
        width: 120px;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #1b4fa8;
          cursor: pointer;
        }

        .code {
          position: relative;
        }
        .code-img {
          position: absolute;
          top: 30px;
          left: -25px;
          height: 120px;
          width: 120px;
          background: #ffffff;
          border: 1px solid #d0d7e6;
          border-radius: 15px;
          .three {
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid #d0d7e6;
            background-color: #fff;
            transform: rotate(45deg);
            top: -5px;
            left: 30px;
          }
          img {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 100;
            border-radius: 15px;
          }
        }
      }

      .member_row {
        text-align: left;
      }
    }
  }

  .no_project {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 200px;

    .no_data {
      margin: 133px 0 0;
      width: 329px;
      height: 283px;
    }

    .add_project {
      margin: 20px auto 0;
      width: 101px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      color: #ffffff;
      background: #1b4fa8;
      cursor: pointer;
    }
  }
  .ipts {
    .el-cascader {
      margin-right: 25px;

      .el-input {
        width: 180px;
        height: 32px !important;
        border: none;

        input {
          width: 100%;
          height: 100%;
          border: 1px solid #f2f4f9;
        }
      }
    }
  }
}
// 分页
.pagination {
  margin: 40px auto;
  text-align: center;
}
</style>

<style lang="less">
.need_index,
.read-dialog {
  .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
      .number {
        height: 30px;
        line-height: 30px;
        color: #1b4fa8;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
      .number:hover {
        color: #1b4fa8 !important;
      }

      .active:hover {
        color: #fff !important;
      }
    }

    .active {
      background-color: #1b4fa8 !important;
    }

    button {
      height: 30px;
      line-height: 30px;
      background-color: #fff !important;
      border: 1px solid #1b4fa8;

      span {
        padding: 0 12px;
        font-size: 16px;
        color: #1b4fa8;
      }
    }

    .el-pagination__jump {
      margin-left: 0;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #1b4fa8;

      .el-input__inner {
        color: #1b4fa8;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
    }
  }
}
.read-dialog {
  .el-dialog {
    height: auto;
    margin-top: 20vh;
  }
}
.v-modal {
  z-index: 8888 !important;
}
.el-dialog__wrapper,
.el-message-box__wrapper {
  z-index: 9999 !important;
}
</style>