<template>
  <div v-loading="loading">
    <div class="company" v-if="company_show === true">
      <!-- 认证 -->
      <div class="company-auth">
        <i class="iconfont iconzhuyi"></i>
        <p>
          企业认证后，云方案小程序内会展示金色V型标志，提升企业形象；已认证企业若需调整企业信息请联系管理员微信：ebainacs
        </p>
        <el-button
          type="primary"
          @click="add_credit_code"
          v-if="companyInfo.authentication === '未认证'"
          >立即认证</el-button
        >
        <!-- 认证 -->
        <div class="auth-icon">
          <span class="r_icon" v-if="companyInfo.authentication === '未认证'">
            <i class="iconfont iconweirenzheng"></i>未认证
          </span>
          <span class="r_icon success" v-else>
            <i class="iconfont icondui"></i>已认证
          </span>
        </div>
      </div>
      <!-- 企业信息 -->
      <!-- 标题 -->
      <div
        class="company-info"
        :class="
          companyInfo.card_front || companyInfo.card_back ? 'bgc' : 'bgc-none'
        "
      >
        <div class="title">
          <div class="title-head d-flex">
            企业管理/企业信息
            <!-- 审核 -->
            <div class="info-icon">
              <span class="r_icon" v-if="companyInfo.status === '待审核'">
                <i class="iconfont iconshenhe1"></i>审核中
              </span>
              <span
                class="r_icon fail"
                v-else-if="companyInfo.status === '审核失败'"
              >
                <i class="iconfont iconshenhe1"></i>审核失败
              </span>
              <span
                class="r_icon success"
                v-else-if="companyInfo.status === '审核通过'"
              >
                <i class="iconfont iconshenhe1"></i>审核通过
              </span>
            </div>
          </div>

          <div class="title-icon" v-if="companyInfo.super_role">
            <div class="btn-edit" @click="edit_companyInfo">
              <i class="iconfont iconE-bianji1"></i>企业信息
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="企业在审核失败的情况下可编辑企业信息"
              placement="right-start"
            >
              <i class="iconfont iconyiwen1"></i>
            </el-tooltip>
          </div>
        </div>

        <ul class="" v-if="companyInfo.card_front || companyInfo.card_back">
          <!-- 名片图片 -->
          <li class="company_logo">
            <div class="l_col">名片图片</div>
            <div class="description">
              <img class="card-img" :src="companyInfo.card_front" alt="" />
              <img class="card-img" :src="companyInfo.card_back" alt="" />
            </div>
          </li>
        </ul>
      </div>

      <div class="company-info bgc">
        <div
          class="title-link"
          v-if="companyInfo.card_front || companyInfo.card_back"
        >
          企业信息
        </div>
        <!-- 内容 -->
        <ul class="">
          <!-- 企业logo -->
          <li class="company_logo">
            <div class="l_col">企业logo</div>
            <div class="description">
              <img :src="companyInfo.tag ? companyInfo.tag.thumb : ''" alt="" />
            </div>
          </li>
          <!-- 企业名称 -->
          <li>
            <div class="l_col">企业名称</div>
            <div class="description company_name">
              <div>{{ companyInfo.company_name }}</div>
            </div>
          </li>
          <!-- 企业简称 -->
          <li>
            <div class="l_col">企业简称</div>
            <div class="description">
              {{ companyInfo.tag ? companyInfo.tag.name : "" }}
            </div>
          </li>
          <!-- 主营方向 -->
          <li v-if="main_projects">
            <div class="l_col">主营方向</div>
            <div class="description">{{ main_projects }}</div>
          </li>
          <!-- 企业简介 -->
          <li v-if="companyInfo.tag.description">
            <div class="l_col">企业简介</div>
            <div class="description">
              {{
                companyInfo.tag
                  ? companyInfo.tag.description
                    ? companyInfo.tag.description
                    : ""
                  : ""
              }}
            </div>
          </li>
          <!-- 企业地址 -->
          <li v-if="companyInfo.address">
            <div class="l_col">企业地址</div>
            <div class="description">{{ companyInfo.address }}</div>
          </li>
          <!-- 企业网址 -->
          <li v-if="companyInfo.company_link">
            <div class="l_col">企业网址</div>
            <div class="description">{{ companyInfo.company_link }}</div>
          </li>
        </ul>
      </div>

      <!-- 联系人信息 -->
      <div class="company-info bgc">
        <div class="title-link">联系人信息</div>
        <ul>
          <li>
            <div class="l_col">联系人</div>
            <div>{{ companyInfo.name }}</div>
          </li>
          <li>
            <div class="l_col">联系方式</div>
            <div>{{ companyInfo.tel }}</div>
          </li>
          <li>
            <div class="l_col">邮箱</div>
            <div>{{ companyInfo.email }}</div>
          </li>
        </ul>
      </div>

      <!-- 企业成员 -->
      <div class="company-child bgc">
        <div class="title-child d-flex">
          <div class="title-mem">企业成员</div>
          <div class="invite_member" @click="invite_member">邀请成员</div>
        </div>

        <!-- 邀请弹窗 -->
        <div class="invite-model" v-show="invite_flag">
          <div class="invite-model-head">邀请成员</div>
          <i class="iconfont iconE-guanbi" @click="invite_member()"></i>

          <!-- 邀请成员 -->
          <div class="invite-model-body">
            <div class="invite-url-head">邀请链接</div>
            <div class="invite-url-tips">
              用户可通过该链接成为方案供应成员，获得上传方案特权
            </div>
            <div class="url-ipt-box d-flex">
              <input
                type="text"
                :value="companyInfo.inviteUrl"
                readonly
              /><el-button type="text" @click="copy_url()">
                复制链接
              </el-button>
            </div>
            <div class="invite-url-tips">该链接30分钟内有效</div>
          </div>
        </div>

        <ul class="member-ul">
          <li class="member-li-header d-flex">
            <div style="width: 25%">时间</div>
            <div style="width: 25%">用户名</div>
            <div style="width: 25%">手机号</div>
            <div style="width: 25%">操作</div>
          </li>

          <li
            class="d-flex"
            v-for="item in companyInfo.supplier_member"
            :key="item.id"
            v-show="companyInfo.supplier_member"
          >
            <div style="width: 25%">
              {{
                item.created_at !== null ? item.created_at.substr(0, 10) : ""
              }}
            </div>
            <div style="width: 25%">
              {{ item.user !== null ? item.user.nickname : "" }}
            </div>
            <div style="width: 25%">
              {{ item.user !== null ? item.user.phone : "" }}
            </div>

            <div
              style="width: 25%"
              class="invite-url"
              @click="unbind_project(item.id)"
              v-if="item.user_id !== userid"
            >
              解除绑定
            </div>

            <div style="width: 25%" v-else></div>
          </li>
          <li v-show="!companyInfo.supplier_member" class="data-empty">
            暂无数据
          </li>
        </ul>
      </div>
    </div>

    <div class="no_project bgc" v-else-if="company_show === false">
      <img class="no_data" src="@/assets/images/no_project_data.png" alt="" />
      <div>暂无企业信息</div>
      <div class="add_project" @click="add_pro">去完善</div>
    </div>

    <!-- 企业认证 -->
    <el-dialog
      class="business_license"
      title="上传营业执照"
      :visible.sync="dialogFormVisible"
    >
      <div>
        <!-- 上传营业执照 -->
        <el-upload
          class="upload-demo"
          :action="uploadHost"
          :data="uploadData"
          :file-list="fileList"
          :show-file-list="false"
          :before-upload="handleBeforeUpload"
          :on-success="handleSuccess"
          list-type="picture"
          accept=".jpg,.jpeg,.png"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">营业执照</div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过2MB
          </div>
        </el-upload>
      </div>

      <div
        class="view_img"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        v-show="business_license"
        @click="upload"
      >
        <img :src="business_license" alt="" />
        <div class="again_upload" v-show="again_box">重新上传</div>
      </div>

      <div class="up_img_text" @click="submit_business_license(companyInfo.id)">
        确认
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from "@/store";
import { oss } from "@/api/oss_upload";
import { getData, postData } from "@/api/user";
export default {
  data() {
    return {
      loading: true,
      ali_oss: store.state.app.ali_oss,
      userid: store.state.app.userInfo.id,
      invite_flag: false,

      company_show: "", // 企业窗口 展示
      companyInfo: {}, // 企业信息
      dialogFormVisible: false, // 上传 营业执照
      business_license: "", // 营业执照
      uploadHost: "", // 附件上传路径
      uploadData: {}, // 附件上传携带参数
      up_file_name: "", // 当前上传图片名 去重
      fileList: [],
      main_projects: "",
      again_box: false, // 重新上传样式
      num: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getData({
        url: `api/b_admin/${this.userid}/project`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.companyInfo = res.data.data || {};
          this.company_show = res.data.data ? true : false;
          this.fileList = this.companyInfo
            ? this.companyInfo.business_license
              ? [{ url: this.companyInfo.business_license }]
              : []
            : [];
          this.business_license = this.companyInfo
            ? this.companyInfo.business_license
              ? this.companyInfo.business_license
              : ""
            : "";
          this.main_projects = this.companyInfo
            ? this.companyInfo.main_projects_gather
              ? this.companyInfo.main_projects_gather.join("，")
              : ""
            : "";
          if (this.companyInfo && this.companyInfo.tender_increment_count) {
            this.num = this.companyInfo
              ? this.companyInfo.tender_increment_count
                ? this.companyInfo.tender_increment_count
                : 0
              : 0;
            localStorage.setItem("num", this.num);
          }

          if (this.companyInfo) {
            store.state.app.userInfo.supplier_id = this.companyInfo.id;
            localStorage.setItem("store_user", JSON.stringify(store.state.app));
          }

          this.loading = false;
        }
      });
    },
    edit_companyInfo() {
      if (this.companyInfo.status === "审核失败") {
        this.$emit("changeTemp", { template: "UserCreate", status: "edit" });
        this.$store.commit("setSupplierId", this.companyInfo.id);
      } else {
        this.$warning("当前状态不支持编辑企业信息");
      }
    },
    // 上传营业执照
    add_credit_code() {
      if (this.companyInfo.authentication === "未认证") {
        this.dialogFormVisible = true;
      } else {
        this.dialogFormVisible = false;
        this.$success("您的企业已认证！");
      }
    },
    // before
    handleBeforeUpload(file) {
      if (!file.type.match(/png|jpg|jpeg/)) {
        this.$err("图片只能是 JPG,JPEG,PNG 格式!");
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }

      let url = "oss_direct/sign_key?dir=b_admin";
      return oss(file.name, url).then((res) => {
        this.uploadHost = res.host;
        this.uploadData = res;
      });
    },
    // Success
    handleSuccess(res, file, fileList) {
      this.fileList = fileList;
      if (res.code === 200) {
        let name = this.ali_oss + res.filename;
        this.business_license = name;
        this.up_file_name = file.name.trim();
      } else {
        this.$warning({
          title: "上传失败",
          desc: "请重新上传",
        });
      }
    },
    // 提交
    submit_business_license(company_id) {
      if (this.business_license === "") {
        this.$err("请选择图片");
      } else {
        postData({
          url: `api/b_admin/${company_id}/upload_license`,
          data: {
            business_license: this.business_license,
          },
        }).then((res) => {
          let data = res.data;
          if (data.code == 200) {
            this.$success(data.data.msg, false, () => {
              this.dialogFormVisible = false;
              location.reload();
            });
          } else if (data.code === 422) {
            this.$warning(data.data);
          }
        });
      }
    },
    // 邀请成员
    invite_member() {
      this.invite_flag = !this.invite_flag;
    },
    // 认证企业
    add_pro() {
      this.$emit("changeTemp", { template: "UserCreate", status: "add" });
    },
    copy_url() {
      let url = this.companyInfo.inviteUrl;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$success("复制成功");
      oInput.remove();
    },
    // 解绑
    unbind_project(id) {
      this.$confirm("确定解绑此成员吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let url = `api/b_admin/${this.userid}/remove_supplier_member`;
          let params = {
            id: id,
          };
          postData({
            url: url,
            data: params,
          }).then((res) => {
            let data = res.data;
            if (data.code == 200) {
              this.dialogVisible = false;
              this.$success(data.data, false, () => {
                this.init();
              });
            } else if (data.code === 422) {
              this.$warning(data.data);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    mouseOver() {
      if (this.business_license) {
        this.again_box = true;
      }
    },
    mouseLeave() {
      this.again_box = false;
    },
    upload() {
      document.querySelector(".el-upload__input").click();
    },
  },
  components: {},
};
</script>

<style lang="less">
.company {
  // 去认证
  .company-auth {
    position: relative;
    display: flex;
    align-items: center;
    height: 46px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    background: #fff1e5;
    border: 1px solid #ffefe0;
    margin-bottom: 10px;
    .iconzhuyi {
      font-size: 19px;
      color: #fe9c4e;
      margin: 0 10px 0 20px;
    }
    .el-button {
      margin-left: 30px;
      width: 81px;
      height: 31px;
      background: #1b4fa8;
      border: none;
      padding: 0;
    }
  }
  // 企业信息
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;

    .title-head {
      font-size: 14px;
      font-weight: 400;
      color: #1b4fa8;
      line-height: 37px;
    }
  }

  // 编辑
  .title-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    cursor: pointer;

    .btn-edit {
      padding: 6px 13px;
      background: #eef4fd;
      border-radius: 15px;
      margin-right: 10px;
      .iconfont {
        font-size: 15px;
        margin-right: 6px;
      }
    }
    .iconyiwen1 {
      font-size: 22px;
    }
  }

  .auth-icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 14px;
    color: #000;
    span {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #999;
    }
    .iconfont {
      font-size: 18px;
      margin-right: 5px;
    }
    span.success {
      color: #1b4fa8;
    }
    span.fail {
      color: #ff0000;
    }
  }

  .info-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    line-height: 36px;
    span {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #fe9c4e;
      margin-left: 40px;
    }
    .iconfont {
      font-size: 18px;
      margin-right: 5px;
    }
    span.success {
      color: #1b4fa8;
    }
    span.fail {
      color: #ff0000;
    }
  }

  .title-link {
    padding: 30px 50px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
  }

  .company-info {
    position: relative;
    ul {
      padding: 30px 0 30px 50px;
      li {
        display: flex;
        margin-bottom: 30px;
        font-size: 14px;
        color: #1a1a1a;
        &:last-child {
          margin-bottom: 0;
        }
        .l_col {
          width: 65px;
          margin-right: 35px;
          font-weight: 400;
          color: #999999;
          flex-shrink: 0;
        }
      }

      .company_logo {
        align-items: center;
        img {
          width: 66px;
          height: 65px;
          border-radius: 50%;
          object-fit: cover;
        }
        img.card-img {
          height: 79px;
          width: 138px;
          margin-right: 24px;
          border: 1px solid #c0ccda;
          border-radius: 6px;
          object-fit: contain;
        }
      }

      .company_name {
        display: flex;
        align-items: center;

        .r_icon {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #1b4fa8;
          cursor: pointer;

          .iconfont {
            margin-left: 17px;
            margin-right: 3px;
            font-size: 18px;
          }
        }
      }

      .description {
        flex: 1;
        max-width: 750px;
      }
    }
  }

  .company-child {
    padding: 20px;
    .title-child {
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 30px 30px;
      .title-mem {
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
      }
      .invite_member {
        padding: 0 17px;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        background: #1b4fa8;
        cursor: pointer;
      }
    }
    .invite-model {
      width: 542px;
      height: 220px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 2px;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      i {
        position: absolute;
        right: -37px;
        top: 0;
        color: #999999;
        font-size: 28px;
        cursor: pointer;
      }

      .invite-model-head {
        height: 60px;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e6e6;
        line-height: 60px;
        padding-left: 20px;
        font-size: 18px;

        font-weight: 400;
        color: rgba(77, 77, 77, 1);
      }

      .invite-model-body {
        padding: 20px;

        .invite-url-head {
          height: 16px;
          line-height: 16px;
          font-size: 16px;

          font-weight: 400;
          color: rgba(77, 77, 77, 1);
        }

        .invite-url-tips {
          height: 13px;
          line-height: 13px;
          font-size: 13px;

          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          margin-top: 10px;
        }

        .url-ipt-box {
          margin-top: 10px;
          height: 40px;

          input {
            width: 380px;
            height: 40px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(230, 230, 230, 1);
            border-radius: 2px;
            margin-right: 20px;
            padding-left: 20px;
            line-height: 40px;
            box-sizing: border-box;
            font-size: 14px;

            font-weight: 400;
            color: rgba(102, 102, 102, 1);
          }

          button {
            width: 100px;
            height: 40px;
            background: rgba(65, 141, 227, 1);
            text-align: center;
            // line-height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            border-radius: 2px;
          }
        }
      }
    }
    .member-ul {
      li {
        justify-content: space-between;
        border-bottom: 1px solid#F2F4F9;
        padding: 0 30px;
        height: 58px;
        line-height: 58px;

        div {
          text-align: center;
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
        }

        .invite-url {
          color: #418de3;
          cursor: pointer;
        }
      }

      .member-li-header {
        height: 60px;
        font-size: 16px;
        color: #1a1a1a;
        background: #f7f8fa;
        border-radius: 2px;
        border: none;

        div {
          line-height: 60px !important;
        }
      }
    }
  }
}
.no_project {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #1a1a1a;
  padding-bottom: 200px;

  .no_data {
    margin: 133px 0 0;
    width: 329px;
    height: 283px;
  }

  .add_project {
    margin: 20px auto 0;
    width: 101px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
    color: #ffffff;
    background: #1b4fa8;
    cursor: pointer;
  }
}

.el-dialog {
  width: 475px;
  height: 420px;
}

.business_license {
  // width: 475px;
  // height: 420px;
  text-align: center;

  .el-dialog__header {
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
  }

  .el-upload-dragger {
    width: 299px !important;
    height: 190px !important;
  }

  .view_img {
    position: absolute;
    top: 84px;
    left: 50%;
    transform: translateX(-50%);
    width: 299px;
    height: 190px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 4px;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
    }

    .again_upload {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      line-height: 190px;
      text-align: center;
      font-size: 18;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }

  .up_img_text {
    margin: 25px auto 0;
    width: 254px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    color: #ffffff;
    background: #1b4fa8;
    cursor: pointer;
  }
}
</style>
