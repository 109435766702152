<template>
  <div class="smt">
    <!-- 导航栏 -->
    <topnav ref="navs" :active="active"></topnav>
    <div class="home-wrap">
      <!-- 背景图 -->
      <div class="carousel_box">
        <div class="smt-form">
          <div class="form-tip" v-if="err">
            <i class="el-icon-error"></i>请填写完整资料
          </div>
          <el-form
            :model="form"
            :rules="rules"
            label-position="top"
            ref="form"
            label-width="80px"
            inline
          >
            <el-form-item label="联系人:" required>
              <el-input
                v-model="form.name"
                placeholder=""
                @input="nameChange"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="联系方式:" required>
              <el-input
                v-model="form.number"
                type="number"
                placeholder=""
                @input="numberChange"
                clearable
              ></el-input>
            </el-form-item>

            <div>
              <el-upload
                class="upload-demo"
                :action="uploadHost"
                :data="bom_uploadData"
                :file-list="bom_list"
                :before-upload="bom_BeforeUpload"
                :on-success="bom_success"
                :on-remove="bom_remove"
                :accept="bom_accept"
                :show-file-list="true"
                :limit="limit"
              >
                <div class="bom-box d-flex">
                  <div class="bom-btn" v-if="bom_show">
                    <img
                      src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_scbomwj.png"
                    />上传BOM文件
                  </div>
                  <p slot="tip" class="el-upload__tip bom-tip" v-if="bom_show">
                    文件必须是xls/xlsx/csv格式，且不超过4MB
                  </p>
                </div>
                <p class="bom-tip up-tip" v-if="!bom_show">
                  文件必须是xls/xlsx/csv格式，且不超过4MB
                </p>
              </el-upload>
            </div>
          </el-form>

          <el-button class="form-btn" @click="submit">立即提交</el-button>
        </div>
      </div>

      <div class="smt-white">
        <!-- 服务 -->
        <div class="smt-serve">
          <ul class="smt-wrap d-flex justify-between">
            <li>
              <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_yzsfw.png"
                alt="一站式服务"
              />
              <div>
                <h5>一站式服务</h5>
                <p>专业的IDH方案定制团队</p>
                <p>从方案到产品的一站式服务</p>
              </div>
            </li>
            <li>
              <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_pzbz.png"
                alt="品质保证"
              />
              <div>
                <h5>品质保证</h5>
                <p>顶尖的技术团队</p>
                <p>较为完善的原材料供应链</p>
              </div>
            </li>
            <li>
              <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_sbbz.png"
                alt="设备保证"
              />
              <div>
                <h5>设备保证</h5>
                <p>百万级无尘车间</p>
                <p>全新的先进进口设备</p>
              </div>
            </li>
            <li>
              <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/icon_ksjf.png"
                alt="快速交付"
              />
              <div>
                <h5>快速交付</h5>
                <p>SMT加急服务</p>
                <p>24小时+24小时,PCBA加工完成</p>
              </div>
            </li>
          </ul>
        </div>
        <!-- 报名 -->
        <div class="smt-apply">
          <div class="smt-wrap d-flex justify-between">
            <div class="smt-action d-flex justify-between">
              <img
                class="flag"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/jb_rmhd.png"
              />
              <div class="action-free">
                <div class="free-title">抽取当月2家报名企业<span>免费打样</span></div>
                <div class="free-tip">江浙沪地区企业即可参加</div>
                <ul class="free-ul">
                  <li><span></span>仅限江浙沪地区企业</li>
                  <li><span></span>中奖企业最多免费打样20份</li>
                  <li><span></span>邮费自理</li>
                </ul>
                <div class="free-count">
                  <span v-for="(item, index) in count" :key="index + '报名'">{{
                    item
                  }}</span>
                  <div class="free-result">已报名</div>
                </div>
                <el-button
                  class="free-btn"
                  @click="apply"
                  :disabled="applyed"
                  >{{ applyed ? "已报名" : "立即报名" }}</el-button
                >
              </div>
              <div class="action-quare">
                <div class="quare-box">
                  <div class="quare-title">报名企业</div>
                  <div class="quare-banner" v-if="apply_list.length > 5">
                    <el-carousel direction="vertical">
                      <el-carousel-item
                        v-for="(v, k) in apply_more_list"
                        :key="k + '轮播'"
                      >
                        <ul class="quare-ul">
                          <li v-for="(item, index) in v" :key="index + '列表'">
                            <span class="company">{{
                              item.company_name
                                ? item.company_name
                                : "******公司"
                            }}</span>
                            <span class="time">{{ item.created }}</span>
                          </li>
                        </ul>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <ul class="quare-ul" v-else-if="apply_list.length > 0">
                    <li
                      v-for="(item, index) in apply_list"
                      :key="index + '报名'"
                    >
                      <span class="company">{{
                        item.company_name
                          ? item.company_name
                          : "******公司"
                      }}</span>
                      <span class="time">{{ item.created }}</span>
                    </li>
                  </ul>
                  <div class="quare-wait" v-else>
                    <img
                      src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/2021331-11038.png"
                    />
                    <p>暂无报名企业</p>
                    <p>快来抢占先机</p>
                  </div>
                </div>
                <div class="win-box">
                  <div class="win-title">上月企业中奖名单</div>
                  <ul class="win-ul" v-if="win_list.length > 0">
                    <li v-for="(item, index) in win_list" :key="index + '中奖'">
                      {{
                        item.company_name
                          ? item.company_name
                          : "******公司"
                      }}
                    </li>
                  </ul>
                  <div class="win-wait" v-else>待公布</div>
                </div>
              </div>
            </div>
            <div class="smt-wechat">
              <h2>云方案-小程序</h2>
              <p class="wechat-tool">营销工具+客户管理</p>
              <p class="wechat-tip">助力企业，转播好方案</p>
              <div class="wechat-code">
                <img
                  src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yfaewm2.png"
                  alt="云方案小程序码"
                />
                <p>扫码入驻</p>
              </div>
              <img
                class="wechat-img"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yfa_sj.png"
                alt="云方案"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 工厂 -->
      <div class="smt-plant">
        <div class="smt-wrap">
          <el-carousel class="smt-banner" height="540px" arrow="always">
            <el-carousel-item v-for="item in smt_list" :key="item">
              <img
                class="banner-img"
                :src="
                  'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/' +
                  item
                "
              />
            </el-carousel-item>
          </el-carousel>
          <div class="plant-info">
            <img
              class="symbol symbol-top"
              src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yh2.png"
              alt=""
            />
            <img
              class="symbol symbol-bottom"
              src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/yh1.png"
              alt=""
            />
            <h3 class="plant-title">工厂介绍</h3>
            <p class="plant-intro">
              南京致诺电子科技有限公司成立于2021年2月4日，以SMT贴片、模块化生产为主，现公司已正式投产运营。公司秉承质量第一，环保优先的政策，在质量方面采取了原料品管、制程品管、成品品管三道品管关卡，确保产品质量之一贯性。核心设备采用世界顶级厂家设备，运营4000平厂房，设备采用了日本YAMAHA贴片机（YSM10，YSM20）。其余设备也均为国内一线厂家，包括德森印刷机、劲拓回流炉、劲拓波峰焊、盟拓AIO。生产团队人员个人能力均为此行业一流水平，严格按照公司生产规范生产，使得产品质量达到一流的水平。
            </p>
            <h3 class="plant-title">联系方式</h3>
            <div class="plant-rela">
              <p>联系人：李经理</p>
              <p>联系电话：15050595751</p>
              <p>联系地址：南京市江宁滨江开发区地秀路757号B栋3楼</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="smt-partner">
        <div class="smt-wrap">
          <h2>合作伙伴</h2>
          <p class="sub">选择我们 带给您更多</p>
          <ul class="smt-part d-flex">
            <li>
              <img
                class="logo"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/lg_abr.png"
                alt="南京艾伯瑞电子科技有限公司"
              />
              <img
                class="pic"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/bg_abr.png"
                alt="南京艾伯瑞电子科技有限公司"
              />
              <h3>南京艾伯瑞电子科技有限公司</h3>
              <div class="txt">
                <p>从事多年方案设计</p>
                <p>几年间有七十几项技术方案落地</p>
                <p>拥有多项独立自主实用型专利技术</p>
              </div>
            </li>
            <li>
              <img
                class="logo"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/lgz-yfa.png"
                alt="易百纳技术社区 & 云方案"
              />
              <img
                class="pic"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/bg_ybn.png"
                alt="易百纳技术社区 & 云方案"
              />
              <h3>易百纳技术社区 & 云方案</h3>
              <div class="txt">
                <p>连接多家长期稳定的品牌供应商</p>
                <p>汇合全方位的供应链服务</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 底部 -->
      <foot />
    </div>

    <!-- 报名确认框 -->
    <el-dialog
      width="422px"
      class="apply-dialog"
      :visible.sync="applyVisible"
      :close="handleClose"
      close-on-click-modal
      show-close
    >
      <div class="dialog-title">确定参与免费打样活动报名？</div>
      <p>免单资格将于下个月月初在网站公布</p>
      <p>若您被抽中我们将短信通知到您</p>
      <p>客服会在24小时内联系您</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyVisible = false">取 消</el-button>
        <el-button type="primary" @click="apply_sure">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 非企业报名填写资料 -->
    <el-dialog
      width="422px"
      class="write-dialog"
      :visible.sync="writeVisible"
      :close="handleCloseWrite"
      close-on-click-modal
      show-close>
      <div class="dialog-title">提交报名信息</div>
      <el-form
        :model="sign"
        :rules="rules"
        ref="sign"
        label-width="70px"
        inline
      >
        <el-form-item label="联系人" prop="contact">
          <el-input
            v-model="sign.contact"
            placeholder=""
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="sign.phone"
            type="number"
            placeholder=""
            clearable
            show-word-limit
            oninput="if(value.length>11)value=value.slice(0,11)"
          ></el-input>
        </el-form-item>

        <el-form-item label="企业名" prop="company_name">
          <el-input
            v-model="sign.company_name"
            placeholder=""
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="writeVisible = false">取 消</el-button>
        <el-button type="primary" @click="apply_ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import { getData, postData } from "@/api/user";
import { oss } from "@/api/oss_upload";
import store from "@/store";
import { setTimeout } from 'timers';

export default {
  name: "Home",
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!this.isCellPhone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      active: "smt",
      smt_list: [
        "gc_ab29256100d694de77ff17446e07a45.jpg",
        "gc_fc4938f30333676e357c98401302df0.jpg",
        "gc_3b77ef2a01eedeebfe556591cc9e8eb.jpg",
        "gc_img_gctp.png",
        "gc_2455c7186cb1c4c7a63012bcf86ffbb.jpg",
        "gc_eac7324bea6b945f5c9b69dd0002d15.jpg",   
        "gc_656cad9473b98a3acbf25efc57e098d.jpg",
        "gc_3b9ad017c6af64b8a1803292f2dfd34.jpg",
        "gc_244a8bb18cc827a5a8b8c95d1ae4a68.jpg",
      ],
      form: {
        name: "",
        number: "",
        bom: "",
      },
      img: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: checkphone,
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入企业名",
            trigger: "blur",
          },
        ],
      },
      apply_list: [],
      apply_more_list: [],
      win_list: [],
      applyVisible: false,
      count: 0,
      applyed: false,

      writeVisible: false,
      sign:{
        contact:'',
        phone:'',
        company_name:'',
      },

      // 上传bom文件
      ali_oss: store.state.app.ali_oss,
      uploadHost: "", // 附件上传路径
      bom_uploadData: {}, // 资料
      bom_list: [], // 资料
      bom_show: true,
      bom_accept: ".xls,.xlsx,.csv",
      limit: 1,
      err: false,
    };
  },
  created() {
    let userInfo = store.state.app.userInfo;
    this.getInfo()
    if(userInfo.id) {
      this.getApply()
    }
  },
  methods: {
    // 获取是否报名
    getApply() {
      let userInfo = store.state.app.userInfo;
      getData({
        url: `api/b_admin/factory?user_id=${userInfo.id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.applyed = data.data.is_exist
        }
      });
    },
    // 获取报名信息
    getInfo() {
      getData({
        url: `api/b_admin/factory`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.apply_list = data.data.list ?  data.data.list: []
          if(this.apply_list.length >5) {
            let list = this.splitArr(this.apply_list,5)
            this.apply_more_list = list.length > 2 ? list : list.concat(list)
          }
          this.win_list = data.data.win ? data.data.win : [];
          this.count = this.PrefixZero(data.data.count, 4).split("");
        }
      });
    },
    splitArr(arr,num) {
      let newArr = []
      for (let i = 0; i < arr.length;) { 
        newArr.push(arr.slice(i, i += num));
      }
      return newArr;
    },
    PrefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    apply () {
      let userInfo = store.state.app.userInfo;
      if (userInfo.id) {
        this.applyVisible = true 
      } else {
        localStorage.setItem("login",'smt') 
        this.$router.push({ path: "/login" });
      }
    },
    apply_sure () {
      let userInfo = store.state.app.userInfo;
      this.applyVisible = false
      if (!userInfo.supplier_id ||
        userInfo.supplier_id === 0 ||
        userInfo.supplier_id === null ||
        userInfo.supplier_id === undefined) {
        // 非企业用户
        this.writeVisible = true
        this.sign.contact = ''
        this.sign.phone = ''
        this.sign.company_name = ''
      } else {
        // 企业用户
        postData({
          url: `api/b_admin/factory/sign_up`,
          data: {
            user_id:  userInfo.id,
            supplier_id: userInfo.supplier_id
          },
        }).then((res) => {
          let data = res.data;
          if (data.code === 200) {
            this.$success(data.data)
            this.getApply()
            setTimeout(()=>{
              this.getInfo()
            },500)
          }else if (data.code === 422) {
            if(data.data.code == 420) {
              this.$err(data.data.msg)
              this.getApply()
            }else {
              this.$err(data.data)
              this.getApply()
            }
          } else {
            this.$err(data.data.msg)
          }
          
        });
      }
    },
    apply_ok () {
      this.$refs["sign"].validate((valid) => {
        if (valid) {
          postData({
            url: `api/b_admin/factory/sign_up`,
            data: {
              user_id: store.state.app.userInfo.id,
              contact:this.sign.contact,
              phone:this.sign.phone,
              company_name:this.sign.company_name
            },
          }).then((res) => {
            let data = res.data;
            if (data.code === 200) {
              this.writeVisible = false
              this.$success(data.data)
              this.getApply()
              setTimeout(()=>{
                this.getInfo()
              },500)
            }else if (data.code === 422) {
              if(data.data.code == 420) {
                this.$err(data.data.msg)
                this.getApply()
              }else {
                this.$err(data.data)
                this.getApply()
              }
            } else {
              this.$err(data.data.msg)
            }
            
          });
        }
      })
    },
    handleClose() {
      this.applyVisible = false;
    },
    handleCloseWrite() {
      this.writeVisible = false
    },
    submit() {
      if (this.form.name == "" || this.form.number == "") {
        this.err = true;
        return false;
      }

      postData({
        url: `api/b_admin/factory/send_smt_mail`,
        data: {
          name: this.form.name,
          phone: this.form.number,
          bom: this.form.bom,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.$success(data.data.msg)
          this.form.name = ''
          this.form.number = ''
          this.form.bom = ''
          this.bom_list = [],
          this.bom_show = true
        } else if(data.code == 422) {
          this.$err(data.data)
          this.bom_show = true
        }else {
          this.$err(data.data.msg)
          this.bom_show = true
        }
      });
    },

    nameChange(e) {
      if (e != "") {
        this.err = false;
      }
    },

    numberChange(e) {
      if (e != "") {
        this.err = false;
      }
    },
     // ------------bom文件---------------------
    bom_BeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 4MB!");
        return false;
      }

      const url = "oss_direct/sign_key?dir=project";
      return oss(file.name, url).then((res) => {
        this.uploadHost = res.host;
        this.bom_uploadData = res;
      });
    },
    bom_success(res, file, fileList) {
      this.bom_list = fileList;
      if (res.code === 200) {
        this.bom_show = false;
        this.form.bom = this.ali_oss + res.filename;
      } else {
        this.bom_show = true;
        this.$warning({
          title: "上传失败",
          desc: "请重新上传",
        });
      }
    },
    bom_remove(file, fileList) {
      this.bom_show = true;
      this.bom_list = fileList;
      this.form.bom = "";
    },
    // ------------bom文件-----end----------------
    //检查手机号
    isCellPhone(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    topnav,
    foot,
  },
};
</script>

<style lang="less">
.smt {
  .home-wrap {
    padding-top: 80px;
  }

  .justify-between {
    justify-content: space-between;
  }

  .smt-wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }

  .carousel_box {
    position: relative;
    height: 560px;
    width: 100%;
    background: url("https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/smt/banner_smtjg.png")
      no-repeat center center;
    background-size: 100% 100%;
  }

  .smt-form {
    position: absolute;
    right: 360px;
    top: 75px;
    width: 620px;
    height: auto;
    padding: 60px 30px;
    background: #fff;
    border: 1px solid #707070;
    opacity: 1;
    border-radius: 15px;
    .form-tip {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 42px;
      padding: 0 18px;
      line-height: 42px;
      background: #fef0f0;
      border: 1px solid #fde9e9;
      opacity: 1;
      border-radius: 15px 15px 0px 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #f56c6c;
      .el-icon-error {
        font-size: 16px;
        margin-right: 12px;
      }
    }
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 20px;
    }
    .el-form-item:nth-child(2) {
      margin-right: 0;
    }

    .el-input__inner {
      height: 46px;
      width: 260px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }

    .el-form-item.is-success .el-input__inner:focus {
      border-color: red;
    }

    .el-form-item__label {
      line-height: 25px;
      padding: 0 0 9px;
    }

    .upload-demo {
      position: relative;
      height: 40px;
      .el-upload-list {
        width: 260px;
      }
      .el-upload-list__item:first-child {
        margin: 0;
      }
      .up-tip {
        position: absolute;
        top: 25px;
        right: 37px;
      }
    }
  }

  .bom-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 60px;
    margin-right: 25px;
    line-height: 60px;
    background: #e3efff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #4d90fb;
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .bom-btn:hover {
    color: #4d90fb;
    background: #f4f8ff;
  }
  .bom-btn:active {
    color: #4d90fb;
    background: #a8cdff;
  }

  .bom-tip {
    width: 236px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
    text-align: left;
  }

  .form-btn,
  .form-btn:focus {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 260px;
    height: 50px;
    margin: 62px auto 0;
    background: #0064f9;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
  }

  .form-btn:hover {
    color: #fff;
    background: #5a9cff;
  }
  .form-btn:active {
    color: #fff;
    background: #004ab7;
  }

  .smt-white {
    background: #fff;
  }

  /* 服务 */
  .smt-serve {
    padding: 120px 0;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 98px;
        width: 98px;
        margin-right: 10px;
      }
      h5 {
        margin-bottom: 7px;
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
        color: #4d90fb;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        color: #666;
      }
    }
  }

  /* 报名 */
  .smt-apply {
    padding-bottom: 120px;
  }

  .smt-action {
    position: relative;
    width: 697px;
    height: 337px;
    padding: 17px 18px 21px 24px;
    background: linear-gradient(270deg, #4d90fb 0%, #70affc 100%);
    opacity: 1;
    border-radius: 15px;
    .flag {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      height: 105px;
      width: 158px;
    }
  }

  .free-title {
    margin: 20px 0 9px;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    text-shadow: 3px 2px 2px #0064f9;
    span {
      color: #ffc26f;
    }
  }

  .free-tip {
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
  }

  .free-ul {
    margin-bottom: 15px !important;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      span {
        width: 4px;
        height: 4px;
        margin-right: 7px;
        background: #ffc26f;
        border-radius: 50%;
      }
    }
  }

  .free-count {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    span {
      width: 30px;
      height: 30px;
      margin-right: 2px;
      background: #fff;
      border-radius: 4px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 29px;
      color: #ff6c47;
      text-align: center;
    }
  }

  .free-result {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
  }

  .free-btn,
  .free-btn.is-disabled {
    width: 200px;
    height: 50px;
    background: #0064f9;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
    border: none;
  }
  .free-btn:hover {
    color: #fff;
    background: #5a9cff;
  }
  .free-btn:active {
    color: #fff;
    background: #004ab7;
  }
  .free-btn.is-disabled,
  .free-btn.is-disabled:hover,
  .free-btn.is-disabled:active {
    color: #fff;
    background: #b4d1ff;
  }

  .action-quare {
    width: 297px;
  }

  .quare-box {
    width: 297px;
    height: 184px;
    margin: 0 0 18px 0;
    padding: 10px 21px 3px 22px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
  }

  .quare-title {
    padding: 0 0 5px;
    margin: 0 0 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #4d90fb;
    border-bottom: 1px solid #eaeaea;
  }

  .quare-banner {
    .el-carousel__indicators {
      display: none;
    }
  }

  .quare-ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #999;
      .company {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .time {
        width: 60px;
        text-align: right;
        color: #9dc3ff;
      }
    }
  }

  .quare-wait {
    text-align: center;
    img {
      height: 46px;
      width: 54px;
      margin: 28px 0 13px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #4d90fb;
    }
  }

  .win-box {
    width: 297px;
    height: 97px;
    padding: 10px 16px 0 22px;
    background: rgba(255, 255, 255, 0.38);
    border-radius: 4px;
  }

  .win-title {
    padding-bottom: 5px;
    margin-bottom: 6px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    border-bottom: 1px solid #eaeaea;
  }

  .win-ul {
    li {
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .win-wait {
    margin: 15px 0 21px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
  }

  .smt-wechat {
    position: relative;
    width: 463px;
    height: 337px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    padding: 33px 0 25px 23px;
    h2 {
      font-size: 23px;
      font-weight: 600;
      line-height: 32px;
      color: #4d90fb;
      text-align: left;
      margin-bottom: 13px;
    }
    .wechat-tool {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #ffb44d;
    }
    .wechat-tip {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #666;
    }
    .wechat-code {
      position: absolute;
      left: 33px;
      bottom: 25px;
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 20px;
        color: #999;
        text-align: center;
      }
    }
    .wechat-img {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 258px;
      width: 264px;
    }
  }

  /* 工厂 */
  .smt-plant {
    padding: 64px 0 56px;
    background: #eceff5;
  }
  .smt-banner {
    position: relative;
    width: 700px;
    border-radius: 12px;
    .el-carousel__arrow {
      display: none !important;
    }
    .el-carousel__indicators {
      left: 108px;
      bottom: 12px;
      .el-carousel__button {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        opacity: 1;
      }
    }

    .el-carousel__indicator.is-active .el-carousel__button {
      width: 22px;
      height: 10px;
      background: #ff6c47;
      border-radius: 10px;
    }
  }
  .banner-img {
    height: 100%;
    width: 100%;
  }
  .plant-info {
    position: absolute;
    right: 0;
    top: 36px;
    z-index: 2;
    padding: 30px 33px 60px 42px;
    width: 588px;
    height: 480px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 1px;

    .plant-title {
      margin-bottom: 8px;
      font-size: 23px;
      font-weight: 600;
      line-height: 32px;
      color: #4d90fb;
    }
    p {
      margin-bottom: 26px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 30px;
      color: #333333;
    }
    .plant-intro {
      margin-bottom: 16px !important;
    }
    .plant-rela {
      margin-bottom: 10px !important;
    }
    .symbol {
      height: 16px;
      width: 18px;
    }
    .symbol-top {
      position: absolute;
      top: 25px;
      right: 24px;
    }
    .symbol-bottom {
      position: absolute;
      left: 33px;
      bottom: 24px;
    }
  }

  /* 合作伙伴 */
  .smt-partner {
    padding: 120px 0;
    background: #fff;
  }
  h2 {
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    color: #333;
    text-align: center;
  }
  .sub {
    margin-bottom: 50px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666;
    text-align: center;
  }
  .smt-part {
    align-items: cneter;
    justify-content: space-between;
    li {
      position: relative;
      width: 580px;
      height: 306px;
      padding: 18px 0 15px 30px;
      background: #fff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 15px;
      .logo {
        position: absolute;
        top: 18px;
        left: 30px;
      }
      .pic {
        position: absolute;
        bottom: 15px;
        right: 0;
      }
      h3 {
        margin-top: 100px;
        margin-bottom: 20px;
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
        color: #4d90fb;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
      .txt {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 90px;
      }
    }
  }
}
.apply-dialog {
  .el-dialog {
    position: relative;
    height: auto;
  }
  .el-dialog__body {
    padding: 0 16px 36px 21px;
  }
  .el-dialog__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 16px 29px 20px;
  }
  .dialog-title {
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #4d90fb;
    letter-spacing: 1px;
  }
  p {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
  }
}

.write-dialog {
  .el-dialog {
    position: relative;
    height: auto;
  }
  .el-dialog__body {
    padding: 0 16px 36px 21px;
  }
  .el-dialog__footer {
    padding: 0 16px 29px 20px;
  }
  .dialog-title {
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #4d90fb;
    letter-spacing: 1px;
  }
  .el-form-item {
    width:100%;
    .el-form-item__content {
      width: calc(100% - 70px);
    }
  }
}
</style>
