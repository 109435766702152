<template>
  <div class="need_squarea">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <div class="wrap">
      <div v-loading="loading">
        <div class="top_search d-flex">
          <div class="main d-flex">
            <div class="hot_tag d-flex">
              <div class="tag_tit">搜索热词：</div>
              <ul class="d-flex">
                <li
                  v-for="(item, index) in tender_hot_words"
                  :key="index"
                  @click="hot_tags(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <div class="ipt_box">
              <input
                :value="search_word"
                type="text"
                placeholder="请输入您要搜索的内容"
                @keyup.13="search_ipt"
                @input="top_ipt_change($event)"
              />
              <el-button icon="el-icon-search" @click="search_ipt"></el-button>
            </div>
            <el-button class="btn-sent" @click="publish">发布需求</el-button>
          </div>
        </div>

        <div class="top_city_classify main">
          <!-- 选择地区 -->
          <div class="city_classify d-flex">
            <div class="tit">选择地区:</div>
            <ul class="d-flex">
              <li
                :class="city_idx === 'all' ? 'active' : ''"
                @click="choose_city('all')"
              >
                全国
              </li>
              <li
                v-for="(item, index) in tender_area"
                :key="index"
                @click="choose_city(item, index)"
                :class="city_idx === index ? 'active' : ''"
              >
                {{ item }}
              </li>
            </ul>
          </div>

          <!-- 需求类型: -->
          <div class="city_classify need_classify d-flex">
            <div class="tit">需求类型:</div>
            <ul class="d-flex">
              <li
                v-for="(item, index) in tender_typical"
                :key="index"
                @click="typical(item, index)"
                :class="type_idx === index ? 'active' : ''"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>

        <div v-if="serach_list && serach_list.length > 0">
          <div class="square_list main" >
            <ul>
              <li class="top_tit d-flex">
                <div class="left_title">
                  <span>地区</span>
                  <span class="content_info">项目信息</span>
                </div>

                <div>发布时间</div>
              </li>

              <li
                v-for="(item, index) in serach_list"
                :key="index"
                @click="show_erweima(item, index)"
                v-show="serach_list && serach_list.length > 0"
              >
                <div class="li_main d-flex">
                  <div class="left_tit d-flex">
                    <span class="city" v-if="item.type !== '项目合作'">{{
                      item.province
                    }}</span>
                    <span class="city" v-else>{{
                      item.area ? item.area.name : "全国"
                    }}</span>

                    <span class="title"> {{ item.title }} </span>
                  </div>

                  <div class="time" v-if="item.type !== '项目合作'">
                    {{ item.publish_at }}
                  </div>
                  <div class="time" v-else>{{ item.updated_at }}</div>
                </div>
              </li>
            </ul>
          </div>
          

          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="page_size"
              :page-sizes="page_sizes"
              layout="prev, pager, next, jumper"
              :total="total"
              prev-text="上一页"
              next-text="下一页"
              background
            >
              <!-- hide-on-single-page -->
            </el-pagination>
          </div>
        </div>
        <div class="no_project" v-else>
          <img class="no_data" src="@/assets/images/no_project_data.png" alt="" />
          <div>暂无数据</div>
        </div>
      </div>
      <!-- 底部 -->
      <foot bgColor="#fff" />
    </div>

    <!-- ------------ -->
    <!-- 遮罩层 展示二维码 -->
    <div class="erweima" v-show="erweima_show">
      <div class="erweima_main">
        <i class="iconfont iconE-guanbi1" @click="hide_erweima"></i>

        <img class="free_use" src="@/assets/images/free_use.png" alt="" />

        <img class="img" :src="erweima_img" alt="" />
        <p>打开微信扫一扫</p>
        <p>详细信息就知晓</p>
      </div>
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import { getData, postData } from "@/api/user";
import store from "@/store";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      active: "need",
      loading: true,
      ali_oss: store.state.app.ali_oss,
      geturl_show: false,

      supplier_list: [],

      city_idx: "all",
      type_idx: 0,
      tender_increment_count: 0, // 今日更新条数
      tender_hot_words: [], // 热词
      tender_area: [], // 地区
      tender_typical: "", // 招标 中标
      serach_list: [], // 列表
      search_hot_word: "", // params 热词
      search_word: "", // params 搜索内容
      search_area: "", // params 地区
      search_typical: "", // params 类型

      erweima_show: false,
      erweima_img: "",

      page: 1, // 分页
      page_size: 10, // 每页条数
      page_sizes: [10, 20, 30, 40], // 分页
      currentPage: 1, // 分页
      total: 0, // 分页
    };
  },
  created() {
    getData({
      url: "api/b_admin/tender_index",
      data: {},
    }).then((res) => {
      let data = res.data;
      if (data.code === 200) {
        this.tender_area = data.data.tender_area;
        this.tender_hot_words = data.data.tender_hot_words;
        this.tender_increment_count = data.data.tender_increment_count || 0;
        this.tender_typical = data.data.tender_typical;
        this.loading = false;
        if(this.tender_increment_count) {
          localStorage.setItem("num", this.tender_increment_count || 0);
        }
        
      }
    });
    if(this.$route.query.type === '3') {
      this.type_idx = 2;
      this.search_typical = '项目合作';
    }
    this.needs_list();
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    get_login_show() {
      this.geturl_show = !this.geturl_show;
    },
    user() {
      this.$router.push({ path: "/user" });
    },
    logout() {
      this.handleLogOut();
    },

    // 热词
    hot_tags(name) {
      // // console.log(name);
      this.search_hot_word = name.trim();
      this.search_word = name.trim();
      this.needs_list();
    },
    //input赋值
    top_ipt_change(e) {
      this.search_word = e.target.value.trim();
    },
    // 头部搜索
    search_ipt() {
      this.needs_list();
    },
    // 选择地区
    choose_city(item, idx) {
      if (item === "all") {
        this.city_idx = "all";
        this.search_area = "";
        // this.needs_list();
      } else {
        this.city_idx = idx;
        this.search_area = item.trim();
        // this.needs_list();
      }

      this.needs_list();
    },
    // 选择类型
    typical(item, idx) {
      this.type_idx = idx;
      this.search_typical = item.trim();
      this.needs_list();
    },
    // 分页
    handleCurrentChange(val) {
      //// console.log(`当前页: ${val}`);
      this.page = val;
      this.needs_list();
    },
    // 列表
    needs_list() {
      let params = {
        search_hot_word: this.search_hot_word,
        search_word: this.search_word,
        search_area: this.search_area,
        search_typical: this.search_typical,
      };

      // console.log("热词 》》", params.search_hot_word);
      // console.log("搜索 》》", params.search_word);
      // console.log("地区 》》", params.search_area);
      // console.log("类型 》》", params.search_typical);
      postData({
        url: `api/b_admin/tender_search?page=${this.page}`,
        data: params,
      }).then((res) => {
        let data = res.data;
        // // console.log("res.data---", data);
        if (data.code === 200) {
          let info = data.data;
          this.serach_list = info.data;
          this.total = info.total;
          this.page_size = info.per_page;
          this.search_hot_word = "";
        }
      });
    },
    show_erweima(item) {
      this.erweima_show = !this.erweima_show;
      let type = 1;
      let typical = this.search_typical;
      if (typical === "中标") {
        type = 2;
      } else if (typical === "项目合作") {
        type = 3;
      }

      getData({
        url: `api/b_admin/tender_get_qrcode_url`,
        data: {
          id: item.id,
          type,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          // // console.log(data.data);
          this.erweima_img = data.data;
        }
      });
    },
    hide_erweima() {
      this.erweima_show = !this.erweima_show;
    },
    // 发布需求
    publish() {
      this.$router.push({ path: "/need_create" });
    },
  },
  components: {
    topnav,
    foot,
  },
  beforeCreate() {
    // 改变背景颜色
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f4f9");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>


<style lang="less" scoped>
.need_squarea {
  background:#f2f4f9;

  .wrap {
    padding-top: 80px;
  }

  .main {
    margin: 0 auto;
    width: 1200px;
  }

  .top_search {
    align-items: center;
    width: 100%;
    height: 124px;
    background: #ffffff;
    border: 1px solid #ecf2f7;

    .main {
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      .hot_tag {
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 1;
        flex-shrink: 0;

        ul {
          align-items: center;

          li {
            margin-left: 16px;
            cursor: pointer;
          }

          li:hover {
            color: #1b4fa8;
          }
        }
      }

      .ipt_box {
        position: relative;
        width: 650px;
        flex-shrink: 0;

        input {
          padding: 0 20px;
          width: 100%;
          height: 50px;
          border: none;
          border-radius: 4px;

          font-size: 14px;
          font-weight: 400;
          color: #999999;
          border: 1px solid #e5e8eb;
          outline: none;
        }

        .el-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 50px;
          background: #1b4fa8;
          color: #fff;
          border: none;
          font-size: 22px;
        }
      }
      .el-button.btn-sent {
        width: 120px;
        height: 50px;
        background: #1b4fa8;
        color: #fff;
        border: none;
      }
    }
  }

  .top_city_classify {
    margin: 16px auto 20px;
    padding: 20px 30px 0;
    background: #ffffff;
    border: 1px solid #ecf2f7;
    border-radius: 10px;

    .city_classify {
      // margin-bottom: 20px;
      // align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 1;
      flex-shrink: 0;

      .tit {
        padding-top: 6px;
        white-space: nowrap;
      }

      ul {
        align-items: center;
        flex-wrap: wrap;
        white-space: nowrap;

        li {
          margin-left: 8px;
          margin-bottom: 10px;
          padding: 6px 8px;

          color: #1a1a1a;
          cursor: pointer;
        }

        .active {
          // padding: 6px 8px;
          color: #fff;
          background: #1b4fa8;
          border-radius: 4px;
        }
      }
    }

    .need_classify {
      margin-top: 10px;
    }
  }

  /* 列表 */
  .square_list {
    position: relative;
    background: #ffffff;
    border: 1px solid #ecf2f7;
    border-radius: 10px;

    ul {
      .top_tit {
        justify-content: space-between;
        padding: 24px 60px 24px 50px;
        font-size: 16px;
        font-weight: 800;
        color: #1b4fa8;

        .left_title {
          .content_info {
            margin-left: 80px;
          }
        }
      }
      .top_tit:hover {
        box-shadow: none;
      }

      li {
        .li_main {
          justify-content: space-between;
          margin: 0 30px;
          padding: 24px 20px;
          font-size: 14px;
          color: #999;
          border-top: 1px solid #f5f6f7;
          cursor: pointer;

          .left_tit {
            .city {
              margin-right: 80px;
              // width: ;
              flex-shrink: 0;
            }

            .title {
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: #1a1a1a;
            }
          }

          .time {
            margin-left: 90px;
            flex-shrink: 0;
          }
        }
      }

      li:hover {
        box-shadow: 0px 0px 7px 0px #dee5f0;

        .title {
          color: #1b4fa8 !important;
        }
      }
    }
  }

  .pagination {
    margin: 40px auto;
    text-align: center;
  }

  .erweima {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;

    .erweima_main {
      position: fixed;
      top: 300px;
      left: 50%;
      transform: translateX(-50%);
      width: 420px;
      height: 440px;
      text-align: center;
      background: #ffffff;
      border-radius: 12px;

      .iconfont {
        position: absolute;
        top: 0;
        right: -40px;
        font-size: 30px;
        color: #fff;
        cursor: pointer;
      }

      .free_use {
        margin: 51px 0 0;
        width: 321px;
        height: 36px;
      }

      .img {
        margin: 22px 0 31px;
        width: 187px;
        height: 189px;
      }

      p {
        font-size: 20px;
        font-weight: 600;
        color: #010101;
        line-height: 30px;
      }
    }
  }
  .no_project {
    width: 1200px;
    margin: 0 auto 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom:160px;
    background: white;
    position: relative;
    background: #ffffff;
    border: 1px solid #ecf2f7;
    border-radius: 10px;

    .no_data {
      margin: 133px 0 0;
      width: 329px;
      height: 283px;
    }

    .add_project {
      margin: 20px auto 0;
      width: 101px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      color: #ffffff;
      background: #1b4fa8;
      cursor: pointer;
    }
  }
  // 分页
  .pagination {
    margin: 40px auto;
    text-align: center;
  }
}
</style>


<style lang="less">
.need_squarea {
  .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
      .number {
        height: 30px;
        line-height: 30px;
        color: #1b4fa8;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
      .number:hover {
        color: #1b4fa8 !important;
      }

      .active:hover {
        color: #fff !important;
      }
    }

    .active {
      background-color: #1b4fa8 !important;
    }

    button {
      height: 30px;
      line-height: 30px;
      background-color: #fff !important;
      border: 1px solid #1b4fa8;

      span {
        padding: 0 12px;
        font-size: 16px;
        color: #1b4fa8;
      }
    }

    .el-pagination__jump {
      margin-left: 0;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #1b4fa8;

      .el-input__inner {
        color: #1b4fa8;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
    }
  }
}
</style>