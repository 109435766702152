<template>
  <div class="nav_border" :class="active === 'need' ? 'active' : ''">
    <header class="nav d-flex">
      <div class="left_logo d-flex">
        <img class="logo" src="@/assets/images/logo.png" alt="LOGO" />
        <ul class="d-flex">
          <li :class="active === 'home' ? 'active' : ''">
            <router-link to="/" @click.native="login('user')">首页</router-link>
          </li>
          <li :class="active === 'smt' ? 'active' : ''">
            <router-link to="/smt" @click.native="login('smt')"
              >SMT加工</router-link
            >
          </li>
          <li class="top_nav_need" :class="active === 'need' ? 'active' : ''">
            <router-link to="/need_square" @click.native="login('user')"
              >需求广场</router-link
            >
            <div class="position_txt">
              <img src="@/assets/images/left_arrows.png" alt="" />
              <div class="line"></div>
              今日更新了{{ num }}条招标信息
            </div>
          </li>
        </ul>
      </div>

      <div class="logo_txt" v-if="!avatar">
        <router-link to="/login">登录</router-link>
      </div>

      <div class="is_login_box d-flex" @click.stop="get_login_show" v-else>
        <img :src="avatar" alt="" />
        <div class="nickname">
          {{ userName }}
          <i class="iconfont iconE-xiala1"></i>
        </div>

        <div class="log_main" v-show="geturl_show">
          <p @click="user">企业管理</p>
          <p @click="logout">退出登录</p>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import { getData } from "@/api/user";
import Cookies from "js-cookie";

export default {
  props: ["active"],
  data() {
    return {
      num: 0,
      avatar: store.state.app.avatarImgPath,
      userName: store.state.app.userName,
      ali_oss: store.state.app.ali_oss,
      geturl_show: false,
      isLogin: false,
    };
  },
  created() {
    this.setNum();
    let that = this;
    document.addEventListener("click", function (e) {
      if (e.target.className !== "is_login_box") {
        that.geturl_show = false;
      }
    });

    let vuex_app = store.state.app;
    if (vuex_app.userId) {
      getData({
        url: `api/b_admin/get_user_base_info`,
        data: { user_id: vuex_app.userId },
      }).then((res) => {
        let data = res.data;
        // console.log("nav.vue", data);

        if (data.code === 200) {
          const userInfo = data.data.info;
          console.log("get_user_base_info>>", userInfo);
          if (userInfo) {
            store.state.app.userInfo.supplier_id = userInfo.supplier_id;
            store.state.app.avatarImgPath = userInfo.avatar;
            store.state.app.userName = userInfo.nickname;

            localStorage.setItem(
              "store_user",
              JSON.stringify(that.$store.state.app)
            );
          } else {
            Cookies.set("b_ebaina_token", "");
          }
        }
      });
    }
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    setNum() {
      this.num = localStorage.getItem("num") || 0;
    },
    get_login_show() {
      this.geturl_show = !this.geturl_show;
    },
    user() {
      this.$router.push({ path: "/user" });
    },
    logout() {
      this.handleLogOut();
    },
    login(type) {
      if (type == "smt") {
        localStorage.setItem("login", "smt");
      } else {
        localStorage.setItem("login", "user");
      }
    },
    loginTo() {
      // console.log(this.isLogin)
    },
  },
};
</script>

<style scoped lang="less">
.nav_border {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  background: white;
  border-bottom: 1px solid #e8ecf0;
}
.nav_border.active {
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.09);
}

.nav {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 1200px;
  height: 80px;

  .left_logo {
    width: 100%;
    align-items: center;

    .logo {
      img {
        width: 51px;
      }
    }

    ul {
      li {
        margin-left: 60px;
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        cursor: pointer;
        a {
          color: #1a1a1a;
        }
      }
      li.active {
        a {
          color: #1b4fa8;
        }
      }
      .top_nav_need {
        position: relative;

        .position_txt {
          position: absolute;
          top: 1px;
          left: 80px;
          padding: 0 6px;
          height: 26px;
          line-height: 26px;
          font-size: 12px;
          font-weight: 400;
          color: #fe9f17;
          background: #fff3e3;
          border: 1px solid #fe9f17;
          border-radius: 4px;
          white-space: nowrap;

          img {
            position: absolute;
            top: 50%;
            left: -5px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            z-index: 2;
          }

          .line {
            position: absolute;
            top: 50%;
            left: -1px;
            transform: translateY(-50%);
            width: 1px;
            height: 6px;
            background: #fff3e3;
          }
        }
      }
    }
  }

  .logo_txt {
    font-size: 18px;
    font-weight: bold;
    flex-shrink: 0;
    a {
      color: #000000;
    }
  }

  .is_login_box {
    position: relative;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;

    img {
      width: 38px;
      height: 37px;
      border-radius: 50%;
    }

    .nickname {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
    }

    .log_main {
      position: absolute;
      top: 43px;
      // left: -81px;
      z-index: 9;
      padding-bottom: 27px;
      width: 121px;
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      text-align: center;
      background: #ffffff;
      border: 1px solid #f2f4f9;

      p {
        padding: 27px 0 0;
        cursor: pointer;
      }

      p:hover {
        color: blue;
      }
    }
  }
}
</style>
