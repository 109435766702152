<template>
  <!-- 动态管理 -->
  <div class="peoject_index">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>

    <div class="bgc" v-loading="loading">
      <div class="title">动态中心/动态管理</div>
      <div class="is_project" v-if="pro_data">
        <el-button class="add_article" @click="add_pro">发布动态</el-button>

        <div class="table_box">
          <el-table
            class="table-project"
            :data="tableData"
            fit
            :row-style="tableRowStyle"
            :cell-style="tableCellStyle"
            :header-cell-style="tableHeaderColor"
          >
            <el-table-column label="动态标题" width="470">
              <template slot-scope="scope">
                <div class="pro_title">
                  {{ scope.row.title }}
                </div>
              </template>
            </el-table-column>
            <el-table-column property="name" label="发布用户"></el-table-column>
            <el-table-column property="time" label="发布时间"></el-table-column>

            <el-table-column property="updated_at" width="150" label="操作">
              <template slot-scope="scope">
                <div class="actions d-flex">
                  <!-- 遮罩层 展示二维码 -->
                  <!--<div class="read_article_erweima" @click="read(scope.row)">
                    查看
                    
                    <div class="erweima" v-show="scope.row.show">
                      <div class="three"></div>
                      <img class="img" :src="erweima_img" alt="" />
                    </div>
                  </div>-->

                  <span @click="edit(scope.row)">编辑</span>
                  <span @click="remove_article(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="page_size"
            :page-sizes="page_sizes"
            layout="prev, pager, next, jumper"
            :total="total"
            background
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
      <div class="no_project" v-else>
        <img class="no_data" src="@/assets/images/no_project_data.png" alt="" />
        <div>暂无动态</div>
        <div class="add_project" @click="add_pro">去添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import store from "@/store";
import { getData } from "@/api/user";
// import { getData, postData } from "@/api/user";

export default {
  data() {
    return {
      loading: true,
      active: "",
      ali_oss: store.state.app.ali_oss,
      wr_base_url: store.state.app.wr_base_url,
      show_view: false,
      pro_data: true,
      dialogVisible: false, //  提示框
      tableData: [],

      page: 1, // 分页
      page_size: 10, // 每页条数
      page_sizes: [10, 20, 30, 40], // 分页
      total: 0, // 分页

      options: [], //  搜索分类
      defaultParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
      erweima_img: "", // 二维码
    };
  },
  created() {
    let that = this;
    let userInfo = store.state.app.userInfo;
    if (
      !userInfo.supplier_id ||
      userInfo.supplier_id === 0 ||
      userInfo.supplier_id === null ||
      userInfo.supplier_id === undefined
    ) {
      this.loading = false;
      setTimeout(() => {
        this.$alert("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            this.$emit("changeTemp", { template: "UserCreate" });
          },
        });
      }, 50);
    } else {
      this.loading = false;
      getData({
        url: `api/b_admin/check_status/${userInfo.supplier_id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.loading = true;
          this.tabelist(1);
          document.addEventListener("click", function (e) {
            if (e.target.className !== "erweima") {
              let list = that.tableData;
              list.forEach((v) => {
                v.show = false;
              });
            }
          });
        } else {
          setTimeout(() => {
            this.$alert("请等待企业审核通过", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.$emit("changeTemp", {
                  template: "UserIndex",
                  item: "1",
                  id: "1-1",
                });
              },
            });
          }, 50);
        }
      });
    }
  },
  methods: {
    tableRowStyle() {
      return {
        color: "#333",
        fontWeight: "400",
        fontSize: "14px",
      };
    },
    tableCellStyle({ columnIndex }) {
      if (columnIndex === 0) {
        return {
          height: "64px",
          padding: "0px 50px 0px 20px",
        };
      } else {
        return {
          height: "64px",
          padding: "0",
        };
      }
    },
    tableHeaderColor({ rowIndex, columnIndex }) {
      if (rowIndex === 0 && columnIndex === 0) {
        return "color: #000;font-weight: 400;font-size: 14px; height: 60px;background: #f7f8fa;padding:20px 0  20px 20px;";
      } else {
        return "color: #000;font-weight: 400;font-size: 14px; height: 60px;background: #f7f8fa;padding:20px 0  20px 0;";
      }
    },
    tabelist(type) {
      let that = this;
      let url = `api/b_admin/industry?page=${this.page}&pagesize=${this.page_size}`;
      getData({
        url: url,
        data: {},
      })
        .then((res) => {
          let data = res.data;
          // console.log("data--->>>>>", data);
          if (data.code === 200) {
            this.show_view = true;
            data.data.data.forEach((v) => {
              v.time = v.created_at.substr(0, 10);
              v.name = v.postcard ? (v.postcard.nickname ? v.postcard.nickname : "") : "";
              v.show = false;
            });
            this.tableData = data.data.data;
            this.total = data.data.total;
            let userinfo = store.state.app.userInfo;
            if (type === 1) {
              if (
                data.data.data &&
                data.data.data.length > 0 &&
                userinfo.supplier_id !== 0
              ) {
                this.pro_data = true;
              } else {
                this.pro_data = false;
              }
            }

            this.loading = false;
          }
        })
        .catch(() => {
          that.$err("请求失败，请检查网络");
          that.loading = false;
        });
    },
    // 编辑
    edit(row) {
      this.$emit("changeTemp", {
        template: "ArticleCreate",
        item: "3",
        id: "3-2",
        status: "edit",
      });
      // this.$router.push({ name: "project_edit" }); // 只能用 name
      // localStorage.setItem("article_edit", JSON.stringify(row));
      this.$store.commit("setArticleId", row.id);
    },
    // 查看
    read(row) {
      // console.log("查看》》》》》》", row);
      let that = this;
      let list = that.tableData;
      list.forEach((v) => {
        if (v.id === row.id) {
          v.show = true;
        } else {
          v.show = false;
        }
      });

      let url = "api/b_admin/specialcolumns/get_qrcode";
      getData({
        url: url,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          that.erweima_img = res.data.data;

          list.forEach((v) => {
            if (v.id === row.id) {
              v.show = true;
            }
          });
        }
      });
    },
    // 删除
    remove_article(row) {
      this.$confirm("确定删除此动态吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let url = `api/b_admin/del_industry/${row.id}`;
          getData({
            url: url,
            data: {},
          }).then((res) => {
            if (res.data.code === 200) {
              this.$success(res.data.data.msg, false, () => {
                this.tabelist();
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 新增
    add_pro() {
      let userInfo = store.state.app.userInfo;
      if (
        !userInfo.supplier_id ||
        userInfo.supplier_id === 0 ||
        userInfo.supplier_id === null ||
        userInfo.supplier_id === undefined
      ) {
        this.$confirm("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$emit("changeTemp", { template: "UserCreate" });
            // this.$router.push({ path: "/User_create" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$emit("changeTemp", {
          template: "ArticleCreate",
          item: "3",
          id: "3-2",
          status: "add",
        });
        // this.$router.push({ path: "/project_create" });
      }
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.page = val;
      this.tabelist();
    },
    dg(list) {
      list.forEach((v) => {
        v.value = v.id;
        v.label = v.name;
        if (v.children) {
          this.dg(v.children);
        }
      });

      return list;
    },
  },
  components: {
    topnav,
  },
};
</script>

<style lang="less" scoped>
.peoject_index {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;
  }

  .is_project {
    padding: 20px;

    .add_article {
      margin-bottom: 20px;
      margin-right: 15px;
      padding: 0;
      width: 90px;
      height: 34px;
      line-height: 0;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      background: #1b4fa8;
      cursor: pointer;
      border-radius: 0;
    }

    .pro_title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    /deep/.el-table {
      overflow: initial;
    }

    /deep/.el-table__body-wrapper {
      overflow: initial;
    }

    /deep/ .cell {
      overflow: initial;
    }

    .actions {
      justify-content: space-between;
      width: 90px;
      text-align: center;

      span,
      .read_article_erweima {
        font-size: 14px;
        font-weight: 400;
        color: #1b4fa8;
        cursor: pointer;
      }

      .read_article_erweima {
        position: relative;

        .erweima {
          position: absolute;
          bottom: -125px;
          left: -13px;
          padding: 5px;
          width: 118px;
          height: 120px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #d0d7e6;
          border-radius: 10px;
          z-index: 999;

          .three {
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid #d0d7e6;
            background-color: #fff;
            transform: rotate(45deg);
            top: -6px;
            left: 22px;
            border-bottom: transparent;
            border-right: transparent;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .no_project {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 160px;

    .no_data {
      margin: 133px 0 0;
      width: 329px;
      height: 283px;
    }

    .add_project {
      margin: 20px auto 0;
      width: 101px;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      color: #ffffff;
      background: #1b4fa8;
      cursor: pointer;
    }
  }

  // 分页
  .pagination {
    margin: 40px auto;
    text-align: center;
  }
}
</style>

<style lang="less">
.peoject_index {
  .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
      .number {
        height: 30px;
        line-height: 30px;
        color: #1b4fa8;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
      .number:hover {
        color: #1b4fa8 !important;
      }

      .active:hover {
        color: #fff !important;
      }
    }

    .active {
      background-color: #1b4fa8 !important;
    }

    button {
      height: 30px;
      line-height: 30px;
      background-color: #fff !important;
      border: 1px solid #1b4fa8;

      span {
        padding: 0 12px;
        font-size: 16px;
        color: #1b4fa8;
      }
    }

    .el-pagination__jump {
      margin-left: 0;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #1b4fa8;

      .el-input__inner {
        color: #1b4fa8;
        border: 1px solid #1b4fa8;
        border-radius: 2px;
      }
    }
  }
}
</style>
