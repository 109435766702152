<template>
  <div class="need_create">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <div class="global_wrap" v-loading="loading">
      <!-- 面包屑 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/need_square' }"
          >需求广场</el-breadcrumb-item
        >
        <el-breadcrumb-item>发布需求</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 页面主题 -->
      <el-form
        class="bgc"
        :model="form"
        :rules="rules"
        ref="form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="需求名称：" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入"
            @input="changeName"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="需求预算：" prop="budget">
          <el-input
            v-model="form.budget"
            type="number"
            placeholder="请输入"
            maxlength="10"
            @input="changeBudget"
          ></el-input>
        </el-form-item>

        <el-form-item label="截止日期：" prop="end_date">
          <el-date-picker
            v-model="form.end_date"
            type="date"
            placeholder="选择日期"
            @change="dateChange"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="需求分类：" prop="needs_type">
          <el-select v-model="form.needs_type" placeholder="请选择">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="业务区域：" prop="area">
          <el-select v-model="form.area" placeholder="请选择">
            <el-option
              v-for="item in areaList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="需求描述：" prop="desc">
          <!-- <p class="refer" @click="exampShow = !exampShow">参考范例↓</p> -->
          <div class="refer">
            <span @click="exampShow = !exampShow">参考范例↓</span>
          </div>
          <el-input
            v-show="exampShow"
            class="example"
            type="textarea"
            :autosize="{ minRows: 13, maxRows: 20 }"
            v-model="form.example"
            disabled
          >
          </el-input>
          <el-input
            class="desc"
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 50 }"
            :placeholder="form.descPlace"
            v-model="form.desc"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="图片：">
          <el-upload
            list-type="picture-card"
            :action="uploadHost"
            :data="uploadData"
            :file-list="fileList"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-change="handleEditChange"
            :on-exceed="handleExceed"
            :class="{ hide: hideUploadEdit }"
            :limit="limit"
            accept=".jpg,.jpeg,.png"
            multiple
          >
            <div class="need-upbox">
              <i class="el-icon-plus"></i>
              <p class="need-uptip">仅支持JPG/PNG格式<br />文件大小不超过5M</p>
            </div>
          </el-upload>
          <el-dialog
            class="need-dialog"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
          >
            <img class="need-preview" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="直接与我沟通：">
          <el-switch
            v-model="form.off"
            active-color="#1B4FA8"
            inactive-color="#999"
          >
            >
          </el-switch>
          <p class="off-tip">打开开关后，申请人将可以直接联系到您。</p>
        </el-form-item>

        <el-button class="create_btn" @click="submit" :disabled="disable_flag"
          >发布需求</el-button
        >

        <div class="need-tips">
          <h5>发布小贴士</h5>
          <p>1.为了让其他用户更好的了解您的需求，需求描述不低于20个字。</p>
          <p>2.需求下线后则不可编辑，下线请谨慎。</p>
          <p>
            3.请勿在需求描述中透露您的联系方式，一经发现，平台将立即删除该需求。
          </p>
          <p>4.您可在“机会”中发现期望与您沟通的用户。</p>
          <p>5.请关注“易百纳技术社区”公众号，实时推送申请沟通的用户记录。</p>
          <p>
            6.不要在图片上放置商业推广信息（除需求信息外），不超过6张，支持批量上传。
          </p>
        </div>
      </el-form>
    </div>
    <!-- 底部 -->
    <foot bgColor="#fff" />
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import store from "@/store";
import { oss } from "@/api/oss_upload";
import { getData, postData } from "@/api/user";

export default {
  data() {
    return {
      // 今天之前的时间 禁止选择
      expireTimeOption: {
        disabledDate(date) {
          let y_date = new Date().getTime() - 3600 * 1000 * 24;
          return date.getTime() <= y_date;
        },
      },
      form: {
        area: "",
        name: "",
        desc: "",
        descPlace: `请输入需求描述`,
        example: `一、需求描述：

在此描述您的项目（可包括用途、功能、效果、性能等）。

示例：项目为基于MIPS处理器的Openwrt嵌入式系统，设备包括多个有线网卡和无线网卡，现要求根据需求开发一套基于web的控制平台，可以控制网内的多个同类型设备。

二、要求：

功能要求：可罗列项目主要的功能需求

示例：1.有设备的状态显示界面，要求所有设备信息都在一个页面内显示。2.有设备的配置界面，要求所有设备的配置都可以在一个页面内进行，不需要重新输入设备IP。

人员要求：在此描述对您对要找的开发人员/合作企业的要求（可包括开发人员的工作年限，技能掌握的熟练程度，地域要求；企业的主营方向，企业资质等）

示例：熟悉嵌入式的基本知识，具有相关平台的开发经验的优先，长三角地区优先。

三、预算：10000元

四、合作方式：

开发方式：远程开发

开发周期：三个月`,
        budget: 0,
        end_date: "",
        needs_type: "",
        off: false,
      },
      loading: true,
      typeList: [], // 需求类型
      areaList: [
        {
          id: 0,
          name: "全国",
        },
      ], // 区域列表
      active: "", // 顶部类型
      userid: store.state.app.userInfo.id,
      exampShow: false, // 范例显示
      disable_flag: false,
      id: null,
      status: "add",
      rules: {
        name: [
          {
            required: true,
            message: "请输入需求名称",
            trigger: "blur",
          },
          {
            max: 30,
            message: "请输入不超过30个字",
            trigger: ["blur", "change"],
          },
        ],
        end_date: [
          {
            required: true,
            message: "截止日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        needs_type: [
          {
            required: true,
            message: "需求分类不能为空",
            trigger: ["blur", "change"],
          },
        ],
        area: [
          {
            required: true,
            message: "请选择区域",
            trigger: ["blur", "change"],
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入需求描述",
            trigger: "blur",
          },
          {
            min: 20,
            message: "请输入不少于20个字",
            trigger: ["blur", "change"],
          },
        ],
      },
      // 上传图片
      ali_oss: store.state.app.ali_oss,
      oss_url: store.state.app.uploadpath,
      uploadHost: "https://ebaina.oss-cn-hangzhou.aliyuncs.com", // 附件上传路径
      uploadData: {}, // 附件上传携带参数
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      imgList: [],
      limit: 6,
      hideUploadEdit: false,
    };
  },
  created() {
    this.id = this.$route.query.id || null;
    this.status = this.$route.query.status || "add";
    this.getArea();
    this.getType();
    // 编辑状态
    if (this.id) {
      getData({
        url: `api/b_admin/demands/edit/` + this.id,
      })
        .then((res) => {
          let data = res.data.data;
          if (res.data.code === 200) {
            this.form.budget = data.budget;
            this.form.end_date = data.closing_timing;
            this.form.needs_type = data.type;
            this.form.area = data.area_id;
            this.form.name = data.title;
            this.form.desc = data.content;
            this.form.imgList = data.thumbs;
            this.form.off = data.on_off && data.on_off === "开";
            this.fileList = [];
            this.form.imgList.forEach((v) => {
              this.fileList.push({
                url: v,
              });
            });
            this.hideUploadEdit = this.form.imgList.length >= 6;
          }
        })
        .catch(() => {
          this.$err("请求失败，请检查网络");
          // that.loading = false;
        });
    }
  },
  methods: {
    changeName(val) {
      if (val.length > 30) {
        this.form.name = val.slice(0, 30);
      }
    },
    // 预算
    changeBudget(val) {
      this.form.budget = val - 0;
    },
    // 截止日期
    dateChange(e) {
      let val = new Date(e);

      let y = val.getFullYear();
      let m =
        val.getMonth() + 1 < 10
          ? "0" + (val.getMonth() + 1)
          : val.getMonth() + 1;
      let d = val.getDate() < 10 ? "0" + val.getDate() : val.getDate();
      let h = val.getHours() < 10 ? "0" + val.getHours() : val.getHours();
      let min =
        val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes();
      let s = val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds();
      let date = `${y}-${m}-${d} ${h}:${min}:${s}`;
      this.form.end_date = date;
    },
    // 获取区域列表
    getArea() {
      getData({
        url: `api/b_admin/demands/area`,
      }).then((res) => {
        if (res.data.code === 200) {
          this.areaList = this.areaList.concat(res.data.data.area_list);
          this.loading = false;
        }
      });
    },
    // 获取 需求类型列表
    getType() {
      getData({
        url: `api/b_admin/demands/publish_demand_type`,
      }).then((res) => {
        if (res.data.code === 200) {
          this.typeList = res.data.data;
        }
      });
    },
    // 提交
    submit() {
      this.imgList = [];
      this.fileList.forEach((v) => {
        if (v.response) {
          this.imgList.push(this.ali_oss + v.response.filename);
        } else {
          this.imgList.push(v.url);
        }
      });
      this.$refs["form"].validate((valid) => {
        let that = this;
        if (valid) {
          this.disable_flag = true;
          let th_id = this.id;
          postData({
            url: th_id ? `api/b_admin/demands/${th_id}` : `api/b_admin/demands`,
            data: {
              area_id: this.form.area,
              title: this.form.name,
              content: this.form.desc,
              thumbs: this.imgList,
              budget: this.form.budget,
              on_off: this.form.off ? "开" : "关",
              closing_timing: this.form.end_date,
              type: this.form.needs_type,
            },
          })
            .then((res) => {
              let data = res.data;
              if (data.code === 200) {
                this.$success(data.data.msg, false, () => {
                  this.$router.push({
                    path: "/need_square",
                    query: { type: "3" },
                  });
                });
              } else {
                this.disable_flag = false;
                this.$err(data.data.msg);
              }
            })
            .catch(() => {
              that.$err("请求失败，请检查网络");
              that.disable_flag = false;
            });
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.hideUploadEdit = fileList.length >= 6;
    },
    handleSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleEditChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 6;
    },
    handleExceed() {
      this.$err("上传图片超过6张！");
    },
    beforeAvatarUpload(file) {
      // if (!file.type.match(/^image\/(jpeg|png|jpg)$/)) {
      //   this.$err("图片只能是 JPEG,JPG,PNG 格式!");
      //   return false;
      // }

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }

      let url = "oss_direct/sign_key?dir=b_admin";
      return oss(file.name, url).then((res) => {
        this.uploadHost = res.host;
        this.uploadData = res;
      });
    },
  },
  components: {
    topnav,
    foot,
  },
};
</script>

<style lang="less">
.need_create {
  background: #f2f4f9;

  .el-breadcrumb {
    margin: 10px 0 20px;
  }

  .el-form {
    position: relative;
    padding: 40px 350px 40px 40px;
    margin-bottom: 10px !important;
    /deep/ .el-form-item {
      position: relative;
      margin-bottom: 30px;
    }
    label {
      font-size: 14px;
      color: #1a1a1a !important;
    }
    .el-input {
      height: 40px;
      input {
        height: 100%;
      }
    }
  }
  // 参考范例
  .refer {
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    font-size: 14px;
    color: #1b4fa8;
    margin-bottom: 10px;
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  textarea {
    padding: 16px 15px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    resize: none;
    font-size: 14px;
    color: #606266;
    line-height: 24px;
  }

  .example.el-textarea.is-disabled .el-textarea__inner {
    margin-bottom: 10px;
    background: #f2f4f9;
    border: 1px solid #1b4fa8;
    font-size: 14px;
    color: #333;
    line-height: 24px;
  }

  .create_btn,
  .create_btn.is-disabled {
    display: inline-block;
    padding: 0 60px;
    margin: 0 0 0 100px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff !important;
    background: #1b4fa8 !important;
    cursor: pointer;
    border-color: #fff;
    &:hover,
    &:link,
    &:active,
    &:focus {
      color: #ffffff !important;
      background: #1b4fa8 !important;
    }
  }
  .need-tips {
    position: absolute;
    top: 40px;
    right: 30px;
    width: 262px;
    h5 {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: #999;
      line-height: 28px;
    }
  }
  // 新增按钮卡
  .el-upload--picture-card {
    display: inline-block;
    width: 160px;
    height: 160px;
    padding: 0 20px;
    line-height: 1;
    .need-upbox {
      margin: 34% 0;
    }
    .need-uptip {
      margin-top: 10px;
      font-size: 12px;
      color: #999;
      line-height: 20px;
    }
  }
  .el-upload-list--picture-card {
    .el-upload-list__item {
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 160px;
      height: 160px;
      margin: 0 8px 8px 0;
      display: inline-block;
    }
    .el-upload-list__item.is-success .el-upload-list__item-status-label {
      display: none;
    }
  }
  .hide .el-upload--picture-card {
    display: none;
  }
  .need-dialog .el-dialog {
    height: auto !important;
    width: 30% !important;
  }
  .need-preview {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .off-tip {
    font-size: 14px;
    font-weight: 500;
    color: #999;
    line-height: 1;
  }
}
</style>
