import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'                            //   登录
import Home from '@/views/Home.vue'                              //   首页
import Smt from '@/views/Smt.vue'                                //   SMT加工
import User from '@/views/User.vue'                              //   企业信息
import UserCreate from '@/views/User_create.vue'                 //   企业认证
import Project_index from '@/views/Project_index.vue'            //   方案管理
import Project_create from '@/views/Project_create.vue'          //   创建方案
import Need_create from '@/views/Need_create.vue'                //   发布需求
import Need_square from '@/views/Need_square.vue'                //   需求广场

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: '/home',
    name: '_home',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/smt',
    name: 'smt',
    component: Smt
  },
  {
    path: '/user',
    name: 'user',
    component: User
  },
  {
    path: '/User_create',
    name: 'User_create',
    component: UserCreate
  },
  {
    path: '/User_edit',
    name: 'User_edit',
    component: UserCreate
  },
  {
    path: '/project_index',
    name: 'project_index',
    component: Project_index,
  },
  {
    path: '/project_create',
    name: 'project_create',
    component: Project_create
  },
  {
    path: '/project_edit',
    name: 'project_edit',
    component: Project_create
  },
  {
    path: '/need_square',
    name: 'need_square',
    component: Need_square
  },
  {
    path: '/need_create',
    name: 'need_create',
    component: Need_create
  },

]

// 解决同路径跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
