<template>
  <div>
    <footer :style="'background:' + bgColor">
      <div class="footer_main">
        <div class="foot_link foot-mid">
          <span class="xcx">
            <span class="xcximg">
              <span class="three"></span>
              <img src="@/assets/images/wx_program.png" alt="" />
            </span>
            <i class="iconfont iconE-xiaochenxu"></i>云方案
          </span>
          <i></i>
          <a
            href="https://www.ebaina.com/aboutus"
            target="_blank"
            rel="nofollow"
            >关于我们</a
          ><i></i
          ><a
            href="https://www.ebaina.com/agreement"
            target="_blank"
            rel="nofollow"
            >使用协议</a
          ><i></i
          ><a
            href="https://www.ebaina.com/privacy"
            target="_blank"
            rel="nofollow"
            >隐私声明</a
          >
        </div>
        <p class="foot_bottom">
          Copyright &copy; 2009-2020 南京启诺信息技术有限公司版权所有.&nbsp;All
          Rights Reserved.&nbsp;苏ICP备14036084
        </p>
      </div>
    </footer>
  </div>
</template>


<script>
export default {
  props: ["bgColor"],
};
</script>


<style scoped lang="less">
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  clear: both; //清除浮动

  width: 100%;
  height: 115px;
  padding-top: 20px;
  text-align: center;
  background-color: #fafafa;

  .footer_main {
    margin: 0 auto;
    width: 1200px;
    text-align: center;

    .foot_link {
      .link-head {
        color: #0068ff;
      }

      line-height: 1;
      font-size: 14px;
      height: 15px;
      line-height: 15px;

      a {
        color: #333333;
      }

      i {
        margin: 0 15px;
      }

      .iconE-xiaochenxu {
        margin: 0 8px 0 0;
        font-size: 17px;
      }
    }

    .foot-mid {
      margin: 20px 0 10px !important;

      .xcx {
        position: relative;
        cursor: pointer;

        .xcximg {
          display: none;
          border: 1px solid #0068ff;
          width: 120px;
          height: 120px;
          position: absolute;
          top: -130px;
          left: -40px;

          .three {
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid #0068ff;
            background-color: #fff;
            transform: rotate(45deg);
            top: 113px;
            left: 58px;
          }

          img {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 100;
          }
        }
      }

      .xcx:hover {
        .xcximg {
          display: block;
        }
      }
    }

    .foot_bottom {
      color: #333333;
      font-size: 13px;
      height: 15px;
      line-height: 15px;
    }
  }
}
</style>
