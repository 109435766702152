<template>
  <div class="add_peoject">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <div class="title">
      动态中心/{{ status === "edit" ? "编辑" : "添加" }}动态
    </div>
    <div class="project-wrap bgc">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <!-- 方案标题 -->
        <el-form-item label="动态标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>

        <!-- 方案介绍 -->
        <el-form-item label="动态内容" prop="introduction">
          <vue-ueditor-wrap
            v-model="form.introduction"
            :config="ueConfig"
            class="editor"
          ></vue-ueditor-wrap>
        </el-form-item>

        <!-- 封面图 -->
        <el-form-item label="动态封面" class="thumbs d-flex">
          <div class="left thumbs_box">
            <el-upload
              ref="lup"
              action=""
              list-type="picture"
              :file-list="fileList"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :http-request="img_upload"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-circle-plus-outline"></i>
              <div class="el-upload__text">封面图</div>
            </el-upload>

            <div
              class="view_img"
              v-show="form.thumb"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
              @click="upload"
            >
              <img :src="form.thumb" alt="" />
              <div class="again_upload" v-show="again_box">重新上传</div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <el-button class="create_btn" @click="submitForm" :disabled="disable_flag"
        >提交</el-button
      >
      <el-button class="back_btn" @click="back">返回</el-button>
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import store from "@/store";
import { getData, postData } from "@/api/user";
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  props: ["status"],
  data() {
    var checkthumbs = (rule, value, callback) => {
      if (!this.form.thumb ) {
        callback(new Error("请上传封面图片"));
      } else {
        callback();
      }
    };
    var checkContent = (rule, value, callback) => {
      if (!this.form.introduction) {
        callback(new Error("请输入动态介绍"));
      }else if (this.form.introduction.replace(/<[^>]+>/g, '').length  <= 150) {
        callback(new Error('请输入150字以上'));
      } else {
        callback();
      }
    };
    return {
      active: "",
      submit_url: "api/b_admin/industry",
      form: {
        title: "",
        introduction: "",
        thumb: "https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202104/13/Lark20210414-111213.png",
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入动态标题",
            trigger: "blur",
          },
        ],
        introduction: [
          {
            required: true,
            validator: checkContent,
            trigger: "change",
          },
        ],
        thumb:[
          {
            required: true,
            validator: checkthumbs,
            trigger: "change",
          },
        ]
      },
      disabled: false,

      defaultMsg: "正文（150字以上）", // 富文本默认提示信息
      /*富文本配置项*/
      ueConfig: {
        toolbars: [
          [
            "bold",
            "|",
            "insertorderedlist",
            "insertunorderedlist",
            "|",
            "paragraph",
            "|",
            "insertimage",
            "|",
            "undo",
            "redo",
          ],
        ],
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        elementPathEnable: false,
        wordCount: true,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      disable_flag: false, // 重复提交
      userCompany: {}, // 用户企业信息

      // 封面图
      again_box: false,
      fileList:[]
    };
  },
  created() {
    let userInfo = store.state.app.userInfo;
    if (
      !userInfo.supplier_id ||
      userInfo.supplier_id === 0 ||
      userInfo.supplier_id === null ||
      userInfo.supplier_id === undefined
    ) {
      setTimeout(() => {
        this.$alert("请先完善企业信息", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            this.$emit("changeTemp", { template: "UserCreate" });
          },
        });
      }, 50);
    } else {
      getData({
        url: `api/b_admin/check_status/${userInfo.supplier_id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          if (this.status === "edit") {
            this.edit_init(store.state.app.article_id);
          }
        } else {
          setTimeout(() => {
            this.$alert("请等待企业审核通过", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.$emit("changeTemp", {
                  template: "UserIndex",
                  item: "1",
                  id: "1-1",
                });
              },
            });
          }, 50);
        }
      });
    }
  },
  methods: {
    // let userid = store.state.app.userInfo.id;
    edit_init(id) {
      this.submit_url = `api/b_admin/industry/${id}`;
      getData({
        url: `api/b_admin/industry/edit/${id}`,
        data: {},
      }).then((res) => {
        // console.log("动态》》》", res);
        let data = res.data;
        if (data.code === 200) {
          let info = res.data.data.info;
          this.form.title = info.title;
          this.form.introduction = info.content;
          this.form.thumb = info.thumb ? info.thumb[0]: [] 
        }
      });
    },
    // ----------封面图片---------------------
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }
    },
    img_upload(e) {
      const formData = new FormData();
      formData.append("name", e.file);
      formData.append("prefix", "b_admin");

      postData({
        url: "/api/upload/single",
        data: formData,
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.form.thumb = data.data.path_url;
        } else {
          this.$err(data.data.msg);
        }
      });
    },
    mouseOver() {
      if (this.form.thumb) {
        this.again_box = true;
      }
    },
    mouseLeave() {
      this.again_box = false;
    },
    upload() {
      this.$refs.lup.clearFiles();
      this.$refs.lup.$children[0].$refs.input.click();
    },
    // ----------封面图片----end-----------------
    // 提交   获取ueditor内容方法 this.editor.getContent()
    submitForm() {
      let that = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.disable_flag = true;

          let url = this.submit_url;
          let params = {
            type:'文章',
            title: that.form.title,
            content: that.form.introduction,
            thumb: that.form.thumb ? [that.form.thumb] :[]
          };
          postData({
            url: url,
            data: params,
          })
            .then((res) => {
              let data = res.data;

              if (data.code === 200) {
                this.$success(data.data.msg, false, () => {
                  // this.$router.push({ path: "/project_index" });
                  that.back();
                });
              } else if (data.code === 422) {
                this.$err(data.data.msg);
                this.disable_flag = false;
              } else if (data.code === 421) {
                this.$err(data.data.msg);
                this.disable_flag = false;
              } else {
                that.$err(data.data.msg);
                this.disable_flag = false;
              }
            })
            .catch(() => {
              that.$err("请求失败，请检查网络");
              this.disable_flag = false;
            });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$emit("changeTemp", {
        template: "ArticleIndex",
        item: "3",
        id: "3-1",
      });
    },
  },
  components: {
    topnav,
    VueUeditorWrap,
  },
};
</script>

<style lang="less" scoped>
.add_peoject {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f8;
    font-size: 14px;
    font-weight: 400;
    color: #1b4fa8;
    line-height: 37px;
  }

  .project-wrap {
    padding: 25px 50px;
  }

  .el-form {
    label {
      margin-right: 16px !important;
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a !important;
    }

    .el-input {
      // width: 300px !important;
      width: 100% !important;
    }
  }

  .create_btn {
    display: inline-block;
    padding: 0 60px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background: #1b4fa8;
    cursor: pointer;
    border-radius: 0;
    margin-left: 80px;
  }

  .create_btn:hover {
    color: #ffffff;
    background: #1b4fa8;
  }

  .back_btn {
    display: inline-block;
    padding: 0 60px;
    margin: 0 0 0 50px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #dcdfe6;
    color: #606266;
    background: #fff;
    cursor: pointer;
  }

  .back_btn:hover {
    color: #606266;
    background: #fff;
    border: 1px solid #dcdfe6;
  }

  .editor {
    line-height: 1;
  }
}
.thumbs {
  .el-form-item__content {
    display: flex;
    margin-left: 0 !important;
  }

  .thumbs_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    width: 180px;
    height: 180px;
    text-align: center;
    color: #2657ac;
    border: 1px solid #e5e5e5;

    i {
      margin-top: 15px;
      font-size: 26px;
    }

    .el-upload__text {
      line-height: 1;
    }

    .el-upload-dragger {
      width: 180px !important;
      height: 180px !important;
    }

    .view_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      height: 180px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 4px;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .again_upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 180px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        z-index: 2;
      }
    }
  }
}
</style>

<style lang="less">
.add_peoject {
  .el-form {
    .el-input {
      // width: 300px;
      width: 100% !important;
      height: 46px;
      border: none;

      input {
        height: 100%;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
