export default {
  // 配置显示在浏览器标签的title
  title: 'B端公司后台',
  // token在Cookie中存储的天数，默认1天
  cookieExpires: 3650,
  // api请求基础路径
  baseUrl: {
    // dev: 'https://www.ebaina.com/',
    dev: 'http://platform.dev.ebaina.com/',
    // pro: 'http://platform.dev.ebaina.com/',
    pro: 'https://www.ebaina.com/',
  },
  // 默认打开的首页的路由name值，默认为home
  homeName: 'home'
}
