import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/libs/config'
import './index.less'
import './assets/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 路由守卫
import { getTokens } from '@/libs/util'
const { homeName } = config

const LOGIN_PAGE_NAME = 'login'
router.beforeEach((to, from, next) => {
  const token = getTokens()
  if (!token && to.name !== LOGIN_PAGE_NAME && to.name !== homeName && to.name !== 'need_square' && to.name !== 'smt') { // 未登录且要跳转的页面不是登录页
    next({
      name: LOGIN_PAGE_NAME
    })
  }
  else if (!token && to.name === LOGIN_PAGE_NAME) {  // 未登陆且要跳转的页面是登录页
    next()
  } else if (token && to.name === LOGIN_PAGE_NAME) { // 已登录且要跳转的页面是登录页
    next({
      name: homeName
    })
  } else {
    next()
  }
})

Vue.prototype.$config = config // 全局注册应用配置
Vue.config.productionTip = false
// 成功提醒
Vue.prototype.$success = function (message, showClose = false, callback, duration = 2000) {
  this.$message({
    message,
    type: 'success',
    duration,
    showClose,
    onClose: callback,
  })
}
// 错误提醒
Vue.prototype.$err = function (message, showClose = false, callback, duration = 2000) {
  this.$message({
    message,
    type: 'error',
    duration,
    showClose,
    onClose: callback,
  })
}
// 警告
Vue.prototype.$warning = function (message, showClose = false, callback, duration = 2000) {
  this.$message({
    message,
    type: 'warning',
    duration,
    showClose,
    onClose: callback,
  })
}
// 普通
Vue.prototype.$msg = function (message, showClose = false, callback, duration = 2000) {
  this.$message({
    message,
    duration,
    showClose,
    onClose: callback,
  })
}
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
