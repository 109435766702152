<template>
  <div class="home">
    <!-- 导航栏 -->
    <topnav ref="navs" :active="active"></topnav>
    <div class="home-wrap">
      <!-- 轮播图 -->
      <div class="carousel_box">
        <img
          src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202011/13/Lark20201113-161518.png"
        />
      </div>

      <!-- 智能销售实现业绩增长，技术赋能打造全民营销 -->
      <div class="main up_down">
        <h2>智能销售实现业绩增长，技术赋能打造全民营销</h2>
        <div class="up_main d-flex">
          <div class="up_item">
            <div class="com_title">
              <h5>智能销售物料</h5>
              <p>销售增强系统-更好的把产品介绍清楚</p>
            </div>

            <div class="box_ul d-flex">
              <div class="box_li up_bgc">
                <p>提升销售</p>
                <p>产品介绍能力</p>
                <h5>210%</h5>
              </div>
              <div class="box_li up_bgc">
                <p>提升销售</p>
                <p>跟进客户能力</p>
                <h5>180%</h5>
              </div>
              <div class="box_li up_bgc">
                <p>提升产品</p>
                <p>标准化输出能力</p>
                <h5>190%</h5>
              </div>
              <div class="box_li down_bgc">
                <p>减少</p>
                <p>物料印刷成本</p>
                <h5>180%</h5>
              </div>
            </div>
          </div>

          <div class="up_item">
            <div class="com_title">
              <h5>智能转介绍</h5>
              <p>销售裂变系统-获取更多线索</p>
            </div>

            <div class="box_ul d-flex">
              <div class="box_li up_bgc">
                <p>提升</p>
                <p>企业线索量</p>
                <h5>210%</h5>
              </div>
              <div class="box_li down_bgc">
                <p>降低</p>
                <p>获客成本</p>
                <h5>190%</h5>
              </div>
              <div class="box_li up_bgc">
                <p>提升</p>
                <p>成交转化率</p>
                <h5>210%</h5>
              </div>
              <div class="box_li up_bgc">
                <p>提升</p>
                <p>企业销售业绩增长</p>
                <h5>200%</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 成为云方案方案商可获得 -->
      <div class="main introduce">
        <h2>成为云方案-方案商可获得</h2>
        <div class="code" @click="showCode">
          &gt;&gt; <b class="code-font">点我扫码进入云方案 </b> &lt;&lt;
          <p class="code-img" v-show="code">
            <span class="three"></span>
            <img src="@/assets/images/wx_program.png" alt="" />
          </p>
        </div>
        <div class="in_main d-flex">
          <div class="in_li">
            <div class="title">小程序免费使用</div>
            <p>
              云方案小程序是基于微信关系链的营销传播工具，便捷传播企业方案，传播数据一目了然，助力企业快速锁定意向客户
            </p>
          </div>

          <span></span>

          <div class="in_li">
            <div class="title">社区定向曝光</div>
            <p>
              社区会定期组织线上/线下技术交流沙龙，供应商可向社区发起活动协办申请，定制串联企业至客户的高粘性活动
            </p>
          </div>

          <span></span>

          <div class="in_li">
            <div class="title">社区媒体矩阵</div>
            <p>
              技术社区及旗下公众号等自媒体大号定向推送优质企业、优质方案，企业可向易百纳供稿营销软文，提供助企业从品宣到获客一站式服务
            </p>
          </div>
        </div>
      </div>

      <!-- 1000+方案商已入驻云方案 -->
      <div class="main good_partners">
        <h2>1000+方案商已入驻云方案</h2>

        <ul class="d-flex">
          <li v-for="item in partners" :key="item.id">
            <img :src="item.tag ? item.tag.thumb : ''" alt="" />
          </li>
        </ul>
      </div>

      <!-- 轻松成为云方案-方案商 -->
      <div class="main project_ebn">
        <h2>轻松成为云方案-方案商</h2>
        <img src="@/assets/images/project_ebn.png" />
        <div @click="get_companyinfo" class="join_ebn">加入云方案</div>
      </div>

      <!-- 底部 -->
      <foot />
    </div>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import { getData } from "@/api/user";
import store from "@/store";
import { mapActions } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      active: "home",
      partners: [],
      ali_oss: store.state.app.ali_oss,
      geturl_show: false,
      code: false,
      num: 0,
    };
  },
  created() {
    var that = this;
    getData({
      url: "api/b_admin/index",
      data: {},
    }).then((res) => {
      //console.log(res);
      let data = res.data;
      if (data.code === 200) {
        this.partners = data.data.supplier_list.splice(0, 8);
        this.num = data.data.tender_increment_count || 0;
        localStorage.setItem("num", this.num);
        this.$refs.navs.setNum();
      }
    });
    document.addEventListener("click", function (e) {
      if (e.target.className !== "code-font") {
        that.code = false;
      }
    });
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    get_login_show() {
      this.geturl_show = !this.geturl_show;
    },
    logout() {
      this.handleLogOut();
    },
    get_companyinfo() {
      let userInfo = store.state.app.userInfo;
      if (userInfo.id) {
        if (userInfo.supplier_id === 0) {
          this.$router.push({ path: "/user" });
        } else if (userInfo.supplier_id !== 0) {
          this.$router.push({ path: "/user" });
        }
      } else {
        localStorage.setItem("login",'user') 
        this.$router.push({ path: "/login" });
      }
    },
    showCode() {
      this.code = true;
    },
  },
  components: {
    topnav,
    foot,
  },
};
</script>


<style lang="less" scoped>
.home {
  background: white;
  padding-bottom: 60px;
  .main {
    margin: 0 auto;
    width: 1200px;

    h2 {
      margin: 125px 0 90px;
      font-size: 36px;
      font-weight: bold;
      color: #1a1a1a;
      text-align: center;
      line-height: 1;
    }
  }

  .home-wrap {
    padding-top: 80px;
  }

  .carousel_box {
    // width: 1920px;
    width: 100%;
    // height: 700px;
    max-height: 700px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100% !important;
      object-fit: contain;
    }
  }

  .up_down {
    .up_main {
      width: 105%;
      margin: 0 auto;
      justify-content: space-between;

      .up_item {
        margin-right: 50px;

        .com_title {
          margin-bottom: 40px;
          text-align: center;
          h5 {
            margin-bottom: 15px !important;
            font-size: 24px;
            font-weight: bold;
            color: #1b4fa8;
            line-height: 1;
          }
          p {
            font-size: 20px;
            font-weight: 400;
            color: #999999;
            line-height: 1;
          }
        }

        .box_ul {
          flex-wrap: wrap;
          width: 110%;

          .box_li {
            margin-right: 25px;
            margin-bottom: 25px;
            padding: 30px 27px;
            width: 275px;
            background: #ffffff;
            // border: 1px solid #F1F1F1;
            // box-shadow: 0px 0px 15px 3px rgba(240, 244, 250, 0.56);
            box-shadow: 0px 0px 5px 3px rgba(240, 244, 250, 0.56);
            flex-shrink: 0;

            p {
              font-size: 20px;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 26px;
            }

            h5 {
              margin-top: 18px;
              text-align: right;
              font-size: 36px;
              font-weight: bold;
              color: #1b4fa8;
              line-height: 1;
            }
          }

          .up_bgc {
            background: url("../assets/images/up_bgc.png") no-repeat top center;
            background-size: 100% 100%;
          }
          .down_bgc {
            background: url("../assets/images/down_bgc.png") no-repeat top
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  .introduce {
    h2 {
      margin: 125px 0 25px;
    }
    .code {
      position: relative;
      margin-bottom: 86px !important;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #1a1a1a;
      b {
        color: #1c52ab;
        cursor: pointer;
      }
      .code-img {
        position: absolute;
        top: 50px;
        left: 50% - 3px;
        height: 230px;
        width: 230px;
        background: #ffffff;
        border: 1px solid #d0d7e6;
        border-radius: 15px;

        .three {
          position: absolute;
          width: 20px;
          height: 20px;
          border: 1px solid #d0d7e6;
          background-color: #fff;
          transform: rotate(45deg);
          top: -10px;
          left: 30px;
        }

        img {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 100;
          border-radius: 15px;
        }
      }
    }
    .in_main {
      justify-content: space-between;

      span {
        border-left: 1px dashed #b1c2f3;
      }

      .in_li {
        .title {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: bold;
          color: #1b4fa8;
          line-height: 1;
        }

        p {
          width: 365px;
          font-size: 18px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 30px;
        }
      }
    }
  }

  .good_partners {
    ul {
      width: 105%;
      flex-wrap: wrap;

      li {
        position: relative;
        margin: 0 27px 18px 0;
        width: 280px;
        height: 141px;

        background: #ffffff;
        border: 1px solid #e5e5e5;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 130px;
          height: 90px;
          object-fit: contain;
        }
      }
    }
  }

  .project_ebn {
    img {
      display: block;
      margin: 0 auto 110px;
      width: 925px;
      height: 138px;
    }

    .join_ebn {
      margin: 0 auto 80px;
      display: block;
      width: 210px;
      height: 60px;
      line-height: 60px;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      background: #1b4fa8;
      cursor: pointer;
    }
  }
}
</style>