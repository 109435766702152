<template>
  <!-- 企业信息 -->
  <div class="user">
    <!-- 导航栏 -->
    <topnav :active="active"></topnav>
    <!-- 页面主体 -->
    <div class="user-wrap">
      <el-menu
        class="left"
        :default-openeds="opend"
        :default-active="open"
        :collapse-transition="false"
      >
        <el-submenu :index="item.id" v-for="item in menu" :key="item.id">
          <template slot="title"
            ><div class="line"></div>
            <i class="title-icon" v-html="item.icon"></i
            >{{ item.text }}</template
          >
          <el-menu-item
            :index="v.id"
            v-for="v in item.sub"
            :key="v.id"
            @click="changeMenu(v.template, item, v)"
            >{{ v.text }}</el-menu-item
          >
        </el-submenu>
      </el-menu>
      <div
        class="right"
        :is="comp"
        :status="status"
        @changeTemp="change($event)"
      ></div>
    </div>
    <foot :bgColor="bg"></foot>
  </div>
</template>

<script>
import UserIndex from "./User_index";
import UserCreate from "./User_create";
import ProjectIndex from "./Project_index";
import ProjectCreate from "./Project_create";
import ArticleIndex from "./Article_index";
import ArticleCreate from "./Article_create";
import NeedIndex from "./Need_index";
import NeedCreate from "./Need_create";
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
// import store from "@/store";
// import { oss } from "@/api/oss_upload";
// import { getData, postData } from "@/api/user";

export default {
  data() {
    return {
      //顶部导航
      active: "",
      // 当前组件
      comp: "userIndex",
      opend: ["1"],
      open: "1-1",
      status: "add",
      // Footer
      bg: "#fff",
      menu: [
        {
          id: "1",
          text: "企业管理",
          icon: '<i class="iconfont iconqiye1"></i>',
          sub: [
            {
              id: "1-1",
              text: "企业信息",
              template: "userIndex",
            },
          ],
        },
        {
          id: "2",
          text: "方案中心",
          icon: '<i class="iconfont iconfangan"></i>',
          sub: [
            {
              id: "2-1",
              text: "方案管理",
              template: "ProjectIndex",
              status: 'edit'
            },
            {
              id: "2-2",
              text: "添加方案",
              template: "ProjectCreate",
              status: 'add'
            },
          ],
        },
        {
          id: "3",
          text: "动态中心",
          icon: '<i class="iconfont iconfaxian"></i>',
          sub: [
            {
              id: "3-1",
              text: "动态管理",
              template: "ArticleIndex",
              status: 'edit'
            },
            {
              id: "3-2",
              text: "发布动态",
              template: "ArticleCreate",
              status: 'add'
            },
          ],
        },
        {
          id: "43",
          text: "需求中心",
          icon: '<i class="iconfont iconxuqiu1"></i>',
          sub: [
            {
              id: "4-1",
              text: "需求管理",
              template: "NeedIndex",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 修改显示模板
    changeMenu(data, item, v) {
      this.status = v.status ? v.status : 'add';
      this.comp = data;
      (this.opend = [item.id]), (this.open = v.id);
    },
    change(data) {
      this.comp = data.template;
      (this.opend = [data.item]), (this.open = data.id);
      this.status = data.status;
    },
  },
  components: {
    topnav,
    foot,
    UserIndex,
    UserCreate,
    ProjectIndex,
    ProjectCreate,
    ArticleIndex,
    ArticleCreate,
    NeedIndex,
    NeedCreate,
  },
};
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  padding-top: 90px;
  background: #f2f4f9;
}

.user-wrap {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto 10px;
}

.left {
  min-height: 500px;
  width: 200px;
  margin-right: 10px;
}

.right {
  flex: 1;
}

.el-submenu {
  /deep/ .el-submenu__title {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 20px 0 0 !important;
    .line {
      height: 50px;
      width: 6px;
      margin-right: 40px;
      background: transparent;
      margin-right: 18px;
    }
    .iconfont {
      font-size: 15px;
      color: #1a1a1a;
      margin: 0 10px 0 0;
    }
    .iconfont.iconfaxian{
      font-size: 18px;
      margin-left: -2px;
    }
    &:hover {
      background-color: #eef4fd !important;
    }
  }

  .el-menu-item {
    height: 50px;
    padding-left: 50px !important;
    background-color: transparent;
    &:hover {
      background-color: #eef4fd !important;
    }
  }

  .el-menu-item.is-active {
    color: #1b4fa8;
  }
}
.is-active {
  /deep/ .el-submenu__title {
    background: #eef4fd;
    color: #1b4fa8;
    .line {
      background: #1b4fa8;
    }
    .iconfont {
      font-size: 15px;
      color: #1b4fa8;
      margin: 0 10px 0 0;
    }
  }
}
</style>